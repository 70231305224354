import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';

const RedirectListener = () => {
  const navigate = useNavigate();

  const handleRedirect = useCallback(({ path }) => {
    if (!path) return;
    navigate(path);
  }, []);

  useCustomEvent(CustomEvents.Redirect, handleRedirect);

  return null;
};

export default RedirectListener;
