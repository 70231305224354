import set from './set';
import { getObjectValue } from './getObjectValue';
import { parseIntSafe } from './parseIntSafe';

const noteSchema = {
  published: { type: 'string' },
  price: { type: 'number', min: 0, max: 1000000 },
  cost: { type: 'number', min: 0, max: 1000000 },
  quantity: { type: 'number', min: 0, max: 1000000 },
  country: { type: 'string', required: true },
  'denomination.value': {
    type: 'number',
    min: 0,
    max: 1000000,
    required: true,
  },
  'denomination.redenominatedValue': { type: 'number', min: 0, max: 1000000 },
  'denomination.name': { type: 'string', required: true },
  'denomination.displayValue': { type: 'string' },
  'grading.grade': { type: 'number', min: 0, max: 1000000 },
  'grading.notes': { type: 'string' },
  'grading.company': { type: 'string' },
  'year.max': { type: 'number', min: 0, max: 2050 },
  'year.min': { type: 'number', min: 0, max: 2050 },
  serial: { type: 'string' },
  tags: { type: 'array', items: { type: 'string' } },
  'catalogue.prefix': { type: 'string' },
  'catalogue.value': { type: 'number', min: 0, max: 1000000 },
  'catalogue.suffix': { type: 'string' },
  extId: { type: 'any' },
  'images.front': { type: 'string' },
  'images.back': { type: 'string' },
  'images.other': { type: 'array', items: { type: 'string' } },
  'images.original.front': { type: 'string' },
  'images.original.back': { type: 'string' },
  'images.original.other': { type: 'array', items: { type: 'string' } },
};

export const formatNoteForUpdate = (note) => {
  let _note = JSON.parse(JSON.stringify(note));
  const {
    country,
    denomination,
    grading,
    tags,
  } = _note;
  if (country) _note.country = _note.country._id;
  if (denomination?.name) _note = set(_note, 'denomination.name', _note.denomination?.name?._id);
  if (grading?.company) _note = set(_note, 'grading.company', _note.grading?.company?._id);
  if (tags) _note.tags = tags.map(tag => tag._id);
  return _note;
};

export const validateNoteUpdates = (note) => {
  const _note = { ...note };
  let _noteUpdates = { };
  const errors = {};
  const fields = Object.keys(noteSchema);
  for (const field of fields) {
    const value = getObjectValue(field, _note);
    const {
      type,
      min,
      max,
      required,
      items,
    } = noteSchema[field];
    const hasValue = value !== undefined && value !== null;
    if (!hasValue && required) {
      errors[field] = 'required';
      continue;
    }
    if (hasValue) {
      let valueType = typeof value;
      if (valueType === 'object' && Array.isArray(value)) valueType = 'array';

      if (valueType !== type && type !== 'any' && (valueType === 'number' && value !== '')) {
        errors[field] = `value must be a ${type}`;
        continue;
      }
      switch (valueType) {
        case 'any': {
          _noteUpdates = set(_noteUpdates, field, value);
          break;
        }
        case 'number': {
          const parsedValue = parseIntSafe(value);
          if (parsedValue < min || parsedValue > max) {
            errors[field] = `value must be between ${min} and ${max}`;
            continue;
          }
          _noteUpdates = set(_noteUpdates, field, parsedValue);
          break;
        }
        case 'string': {
          _noteUpdates = set(_noteUpdates, field, value);
          break;
        }
        case 'array': {
          const isValid = value.every(item => {
            const _itemType = typeof item;
            return _itemType === items.type;
          });
          if (!isValid) {
            errors[field] = `value must be an array of ${items.type}s`;
            continue;
          }
          _noteUpdates = set(_noteUpdates, field, [...value]);
          break;
        }
        case 'object': {
          _noteUpdates = set(_noteUpdates, field, { ...value });
          break;
        }
        default:
          continue;
      }
    }
  }
  return {
    updates: _noteUpdates,
    errors,
  };
};

export const getNoteDifferences = (note, updates) => {
  const differences = { };
  const fields = Object.keys(noteSchema);
  for (const field of fields) {
    const value = getObjectValue(field, note);
    const updateValue = getObjectValue(field, updates);
    if ((value === undefined || value === null) && (updateValue === undefined || updateValue === null)) continue;
    if (JSON.stringify(value) !== JSON.stringify(updateValue)) {
      differences[field] = {
        value,
        updateValue,
      };
    }
  }
  return differences;
};
