import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import {
  TextField,
  Grid,
  Chip,
  Checkbox,
} from '@mui/material';
import noteListSettingsState from '../../models/noteListSettings/atom';
import DenominationSelect from '../DenominationSelect';
import CountrySelect from '../CountrySelect';
import './style.css';
import GradingCompanySelect from '../GradingCompanySelect';

const sortOptions = [
  { label: 'Catalogue Ascending', value: { 'sortIndex.catalogue': 1 } },
  { label: 'Catalogue Descending', value: { 'sortIndex.catalogue': -1 } },
  { label: 'Denomination Ascending', value: { 'denomination.value': 1 } },
  { label: 'Denomination Descending', value: { 'denomination.value': -1 } },
  { label: 'Grade Ascending', value: { 'grading.grade': 1 } },
  { label: 'Grade Descending', value: { 'grading.grade': -1 } },
  { label: 'Year Ascending', value: { 'year.min': 1 } },
  { label: 'Year Descending', value: { 'year.min': -1 } },
  { label: 'Country Ascending', value: { 'sortIndex.country': 1 } },
  { label: 'Country Descending', value: { 'sortIndex.country': -1 } },
];

const NoteListFilter = ({ atomKey, showQuantity = false, showAdmin = false }) => {
  const [filters, setFilters] = useRecoilState(noteListSettingsState(atomKey));
  const [newFilters, setNewFilters] = useRecoilState(noteListSettingsState(`${atomKey}_new`));

  const addSortOption = useCallback((sortOption) => {
    setNewFilters(prev => {
      const isSelected = prev.sort.find(option => option.label === sortOption.label);
      if (isSelected) return prev;
      return { ...prev, sort: [sortOption] };
    });
  }, []);

  const removeSortOption = useCallback((sortOption) => {
    setNewFilters(prev => ({ ...prev, sort: prev.sort.filter(option => option.label !== sortOption.label) }));
  }, []);

  const setDenominations = useCallback((denomination) => setNewFilters(prev => ({ ...prev, denomination })), []);

  const setCountries = useCallback((country) => setNewFilters(prev => ({ ...prev, country })), []);

  const setGradingCompanies = useCallback((gradingCompany) => setNewFilters(prev => ({ ...prev, gradingCompany })), []);

  const setMinValue = useCallback((e) => setNewFilters(prev => ({ ...prev, value: { ...prev.value, min: e.target.value } })), []);

  const setMaxValue = useCallback((e) => setNewFilters(prev => ({ ...prev, value: { ...prev.value, max: e.target.value } })), []);

  const setMaxYear = useCallback((e) => setNewFilters(prev => ({ ...prev, year: { ...prev.year, max: e.target.value } })), []);

  const setMinYear = useCallback((e) => setNewFilters(prev => ({ ...prev, year: { ...prev.year, min: e.target.value } })), []);

  const setMinPrice = useCallback((e) => setNewFilters(prev => ({ ...prev, price: { ...prev.price, min: e.target.value } })), []);

  const setMaxPrice = useCallback((e) => setNewFilters(prev => ({ ...prev, price: { ...prev.price, max: e.target.value } })), []);

  const setMinGrade = useCallback((e) => setNewFilters(prev => ({ ...prev, grade: { ...prev.grade, min: e.target.value } })), []);

  const setMaxGrade = useCallback((e) => setNewFilters(prev => ({ ...prev, grade: { ...prev.grade, max: e.target.value } })), []);

  const setMaxQuantity = useCallback((e) => setNewFilters(prev => ({ ...prev, quantity: { ...prev.quantity, max: e.target.value } })), []);

  const setMinQuantity = useCallback((e) => setNewFilters(prev => ({ ...prev, quantity: { ...prev.quantity, min: e.target.value } })), []);

  const setNoImages = useCallback((e) => setNewFilters(prev => ({ ...prev, noImages: e.target.checked })), []);

  const setIncludeArchived = useCallback((e) => setNewFilters(prev => ({ ...prev, archived: e.target.checked })), []);

  const setPublished = useCallback((e) => setNewFilters(prev => ({ ...prev, published: e.target.checked })), []);

  const setUnpublished = useCallback((e) => setNewFilters(prev => ({ ...prev, unpublished: e.target.checked })), []);

  const denominations = useMemo(() => (
    <div className='filter-group'>
      <div className='filter-group-label pb-2'>denominations</div>
      <DenominationSelect size='small' denominations={newFilters.denomination} setDenominations={setDenominations} selectType='multiple' />
    </div>
  ), [newFilters.denomination]);

  const countries = useMemo(() => (
    <div className='filter-group'>
      <div className='filter-group-label pb-2'>countries</div>
      <CountrySelect size='small' countries={newFilters.country} setCountries={setCountries} selectType='multiple' />
    </div>
  ), [newFilters.country]);

  const gradingCompanies = useMemo(() => (
    <div className='filter-group'>
      <div className='filter-group-label pb-2'>grading companies</div>
      <GradingCompanySelect size='small' gradingCompanies={newFilters.gradingCompany} setGradingCompanies={setGradingCompanies} selectType='multiple' />
    </div>
  ), [newFilters.gradingCompany]);

  const value = useMemo(() => (
    <div className='filter-group'>
      <div className='filter-group-label'>denomination value</div>
      <div className='filter-item-label'>min</div>
      <TextField
        type='number'
        inputProps={{
          pattern: '[0-9]*',
          inputMode: 'numeric',
        }}
        onChange={setMinValue}
        value={newFilters.value.min}
        fullWidth
        size='small'
      />
      <div className='filter-item-label'>max</div>
      <TextField
        type='number'
        inputProps={{
          pattern: '[0-9]*',
          inputMode: 'numeric',
        }}
        onChange={setMaxValue}
        value={newFilters.value.max}
        fullWidth
        size='small'
      />
    </div>
  ), [newFilters.value]);

  const price = useMemo(() => (
    <div className='filter-group'>
      <div className='filter-group-label'>price</div>
      <div className='filter-item-label'>min</div>
      <TextField
        type='number'
        inputProps={{
          pattern: '[0-9]*',
          inputMode: 'numeric',
        }}
        onChange={setMinPrice}
        value={newFilters.price.min}
        fullWidth
        size='small'
      />
      <div className='filter-item-label'>max</div>
      <TextField
        type='number'
        inputProps={{
          pattern: '[0-9]*',
          inputMode: 'numeric',
        }}
        onChange={setMaxPrice}
        value={newFilters.price.max}
        fullWidth
        size='small'
      />
    </div>
  ), [newFilters.price]);

  const grade = useMemo(() => (
    <div className='filter-group'>
      <div className='filter-group-label'>grade</div>
      <div className='filter-item-label'>min</div>
      <TextField
        type='number'
        inputProps={{
          pattern: '[0-9]*',
          inputMode: 'numeric',
        }}
        onChange={setMinGrade}
        value={newFilters.grade.min}
        fullWidth
        size='small'
      />
      <div className='filter-item-label'>max</div>
      <TextField
        type='number'
        inputProps={{
          pattern: '[0-9]*',
          inputMode: 'numeric',
        }}
        onChange={setMaxGrade}
        value={newFilters.grade.max}
        fullWidth
        size='small'
      />
    </div>
  ), [newFilters.grade]);

  const year = useMemo(() => (
    <div className='filter-group'>
      <div className='filter-group-label'>year</div>
      <div className='filter-item-label'>min</div>
      <TextField
        type='number'
        inputProps={{
          pattern: '[0-9]*',
          inputMode: 'numeric',
        }}
        onChange={setMinYear}
        value={newFilters.year.min}
        fullWidth
        size='small'
      />
      <div className='filter-item-label'>max</div>
      <TextField
        type='number'
        inputProps={{
          pattern: '[0-9]*',
          inputMode: 'numeric',
        }}
        onChange={setMaxYear}
        value={newFilters.year.max}
        fullWidth
        size='small'
      />
    </div>
  ), [newFilters.year]);

  const sort = useMemo(() => (
    <div style={{ columnGap: '4px', rowGap: '8px' }} className='filter-group flex flex-wrap mb-4 tablet:max-w-[500px]'>
      { sortOptions.map(option => {
        const isSelected = newFilters.sort.find(so => so.label === option.label);
        return (
          <Chip
            size='small'
            key={option.label}
            label={option.label}
            color={isSelected ? 'primary' : 'default'}
            onClick={() => addSortOption(option)}
            onDelete={isSelected ? () => removeSortOption(option) : null}
          />
        );
      }) }
    </div>
  ), [newFilters.sort]);

  const admin = useMemo(() => {
    if (!showAdmin) return null;
    return (
      <div className='filter-group'>
        <div className='filter-group-label'>other</div>
        <div className='filter-item-label'>admin</div>
        <div className='flex items-center'>
          <Checkbox onChange={setIncludeArchived} checked={newFilters.archived} size='small' label={{ inputProps: { 'aria-label': 'archived' } }} />
          <span className='text-sm'>show only archived notes</span>
        </div>
        <div className='flex items-center'>
          <Checkbox onChange={setNoImages} checked={newFilters.noImages} size='small' label={{ inputProps: { 'aria-label': 'archived' } }} />
          <span className='text-sm'>show only notes with no back and front images</span>
        </div>
        <div className='flex items-center'>
          <Checkbox onChange={setPublished} checked={newFilters.published} size='small' label={{ inputProps: { 'aria-label': 'archived' } }} />
          <span className='text-sm'>show published notes</span>
        </div>
        <div className='flex items-center'>
          <Checkbox onChange={setUnpublished} checked={newFilters.unpublished} size='small' label={{ inputProps: { 'aria-label': 'archived' } }} />
          <span className='text-sm'>show unpublished notes</span>
        </div>
      </div>
    );
  }, [newFilters.archived, newFilters.noImages, newFilters.published, newFilters.unpublished, showAdmin]);

  const quantity = useMemo(() => {
    if (!showQuantity) return null;
    return (
      <div className='filter-group'>
        <div className='filter-group-label'>quantity</div>
        <div className='filter-item-label'>min</div>
        <TextField
          type='number'
          inputProps={{
            pattern: '[0-9]*',
            inputMode: 'numeric',
          }}
          onChange={setMinQuantity}
          value={newFilters.quantity.min}
          fullWidth
          size='small'
        />
        <div className='filter-item-label'>max</div>
        <TextField
          type='number'
          inputProps={{
            pattern: '[0-9]*',
            inputMode: 'numeric',
          }}
          onChange={setMaxQuantity}
          value={newFilters.quantity.max}
          fullWidth
          size='small'
        />
      </div>
    );
  }, [newFilters.quantity]);

  const DEV = useMemo(() => (
    <>
      <div className='py-2'>{JSON.stringify(filters, null, 2)}</div>
      <div className='py-2'>{JSON.stringify(newFilters, null, 2)}</div>
    </>
  ), [filters, newFilters]);

  return (
    <div className='px-2 py-2 rounded'>
      <div className='font-bold text-[16px]'>Sort</div>
      { sort }
      <div className='font-bold text-[16px]'>Filter</div>
      { denominations }
      { countries }
      { gradingCompanies}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          { value }
        </Grid>
        <Grid item xs={12} sm={6}>
          { grade }
        </Grid>
        <Grid item xs={12} sm={6}>
          { price }
        </Grid>
        <Grid item xs={12} sm={6}>
          { year }
        </Grid>
        { showQuantity && (
        <Grid item xs={12} sm={6}>
          { quantity }
        </Grid>
        ) }
        { showAdmin && (
        <Grid item xs={12} sm={6}>
          { admin }
        </Grid>
        ) }
      </Grid>
    </div>
  );
};

export default NoteListFilter;
