import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import {
  Button,
} from '@mui/material';
import noteListSettingsState, { INITIAL_FILTER_STATE } from '../../models/noteListSettings/atom';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';

const NoteListFilterActions = ({ atomKey }) => {
  const [filters, setFilters] = useRecoilState(noteListSettingsState(atomKey));
  const [newFilters, setNewFilters] = useRecoilState(noteListSettingsState(`${atomKey}_new`));

  const hasUnappliedChanges = useMemo(() => JSON.stringify(filters) !== JSON.stringify(newFilters), [newFilters, filters]);

  const filterIsPristine = useMemo(() => JSON.stringify(filters) === JSON.stringify(INITIAL_FILTER_STATE), [filters]);

  const setInitialFilter = useCallback(() => {
    setFilters({ ...INITIAL_FILTER_STATE });
    setNewFilters({ ...INITIAL_FILTER_STATE });
  }, [setNewFilters]);

  const discardChanges = useCallback(() => setNewFilters({ ...filters }), [filters]);

  const applyChanges = useCallback(() => {
    setFilters({ ...newFilters });
    emitCustomEvent(CustomEvents.CloseNoteFilterModal);
  }, [newFilters]);

  return (
    <div className='pt-3 flex justify-end'>
      <div className='space-x-3'>
        <Button
          onClick={!hasUnappliedChanges ? setInitialFilter : discardChanges}
          disabled={filterIsPristine && !hasUnappliedChanges}
          variant='contained'
        >
          { !hasUnappliedChanges ? 'Clear All' : 'Discard Changes' }
        </Button>
        <Button
          onClick={applyChanges}
          disabled={JSON.stringify(newFilters) === JSON.stringify(filters)}
          variant='contained'
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default NoteListFilterActions;
