import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ApiClient from '../../clients/api';
import { useAuthenticatedUser } from '../../models/user/useAuthenticatedUser';
import { Wrapper, Content } from '../../components/Containers';
import { checkPassword } from '../../lib/validateUser';
import { SignUpFormClasses } from '../../lib/constants';
import PasswordInput from '../../components/PasswordInput';

const Login = () => {
  // Hooks
  const navigate = useNavigate();
  const { loading, data: authenticated } = useAuthenticatedUser();

  // Local State
  const [loginError, setLoginError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValid, setIsValid] = useState(null);

  // Redirect: User is signed in
  useEffect(() => {
    if (authenticated) navigate(-1);
  }, [authenticated]);

  // Validation
  useEffect(() => {
    if (loginError) setLoginError(false);
    if (email?.length > 3 && checkPassword(password)) setIsValid(true);
    else setIsValid(false);
  }, [email, password]);

  // Handlers
  const handleEmail = useCallback((e) => setEmail(e.target.value), []);
  const handlePassword = useCallback((e) => setPassword(e.target.value), []);
  const handleLogin = useCallback(async () => {
    const res = await ApiClient.login({ email, password });
    if (!res?.success) return setLoginError(true);
  }, [email, password]);

  const handleKeyPress = useCallback((e) => {
    if (!isValid) return null;
    if (e.key === 'Enter') handleLogin();
  }, [isValid]);

  // Guard Clause: Auth state is still loading
  if (loading) return null;

  return (
    <Wrapper applyBreakpoints className='login'>
      <Content className={SignUpFormClasses.Content}>
        <div className={SignUpFormClasses.MainDiv}>
          <h1 className='font-bebas text-4xl text-primary-text text-center'>Login</h1>
          <form className={SignUpFormClasses.Form}>
            <TextField
              autoComplete='email'
              onKeyPress={handleKeyPress}
              error={loginError}
              sx={{ mt: 2 }}
              onChange={handleEmail}
              value={email}
              fullWidth
              label='email or username'
            />
            <PasswordInput
              autoComplete='current-password'
              handleChange={handlePassword}
              onKeyPress={handleKeyPress}
              error={loginError}
              sx={{ mt: 2 }}
              fullWidth
              value={password}
              label='password'
            />
            <Button size='large' sx={{ mt: 2 }} disabled={!isValid || loginError} onClick={handleLogin} fullWidth variant='contained'>Sign In</Button>
          </form>
        </div>
      </Content>
    </Wrapper>
  );
};

export default Login;
