import { useRecoilValue } from 'recoil';
import {
  userLoadingSelector,
  userErrorSelector,
  userDataSelector,
  unauthenticatedSelector,
} from './selector';

export const useAuthenticatedUser = () => {
  const loading = useRecoilValue(userLoadingSelector);
  const error = useRecoilValue(userErrorSelector);
  const data = useRecoilValue(userDataSelector);
  const unauthenticated = useRecoilValue(unauthenticatedSelector);

  return {
    data,
    loading,
    error,
    unauthenticated,
  };
};
