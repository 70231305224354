import ReactGA from 'react-ga4';
import { useBreakpointName } from '../../hooks/useBreakpointName';

const { REACT_APP_GA } = process.env;

const initGA = () => {
  if (REACT_APP_GA !== undefined) ReactGA.initialize(REACT_APP_GA);
};

const HooksAndAnalytics = () => {
  initGA();
  useBreakpointName();
  return null;
};

export default HooksAndAnalytics;
