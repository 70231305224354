import axios from 'axios';
import { setRecoil } from 'recoil-nexus';
import { nanoid } from 'nanoid';
import { SiteCookies } from '../lib/constants';
import Cookies from './cookies';
import sleep from '../lib/sleep';
import { userDataSelector, userLoadingSelector, userErrorSelector } from '../models/user/selector';

class ApiClient {
  constructor() {
    this.client = null;
    this.apiBaseUrl = process.env.REACT_APP_API_BASE;
  }

  initClient = () => {
    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      headers: {
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
    });

    this.client.interceptors.request.use((config) => {
      const updatedConfig = { ...config };
      updatedConfig.headers.authkey = Cookies.get(SiteCookies.AuthKey);
      return updatedConfig;
    }, (err) => Promise.reject(err));

    this.client.interceptors.response.use(res => {
      if (res?.headers?.authkey && res?.headers?.authkey !== 'null') Cookies.set(SiteCookies.AuthKey, res.headers.authkey);
      else Cookies.remove(SiteCookies.AuthKey);
      return res;
    }, (err) => Promise.reject(err));
  };

  buildQuery = (params) => {
    const query = [];
    Object.entries(params).forEach(([key, value]) => {
      if (value !== null || value !== undefined) query.push(`${key}=${value}`);
    });
    return query.length > 0
      ? `?${query.join('&')}`
      : '';
  };

  /**
   *
   * @param {object} data - body of request
   * @param {object} headers - headers for request
   * @param {string} method - HTTP method
   * @param {string} path - path for endpoint
   * @param {boolean} catchError - if the error should be swallowed
   * @param {object} queryParams - query params+
    * @param {object} retryAttempts - retry max attempts
   * @param {boolean|string} snackbarError - errorMessage: display error, true will show generic message
   * @returns {Promise} API response
   */
  sendRequest = async ({
    data,
    method,
    retryTimes = 0,
    retryAttempts = 0,
    queryParams,
    path,
    headers,
    catchError = false,
    snackbarError = false,
    forceError = false,
  }) => {
    try {
      if (forceError) throw new Error('Forced error');
      if (!method) throw new Error('Method is required');
      if (!path) throw new Error('Path is required');
      if (!this.client) {
        return {
          success: false,
          error: 'api client not found',
        };
      }
      const query = queryParams ? this.buildQuery(queryParams) : '';
      const res = await this.client[method.toLowerCase()](`${this.apiBaseUrl}${path}${query}`, data, headers || {});
      const _res = {
        success: res.status >= 200 && res?.status < 300,
        data: res?.data,
      };
      if (!retryTimes || _res.success) return _res;
      if (!_res.success) {
        await sleep(retryAttempts * 1000);
        return this.sendRequest({
          data,
          method,
          retryAttempts: retryAttempts + 1,
          retryTimes: retryTimes - 1,
          queryParams,
          path,
          headers,
          catchError,
          snackbarError,
          forceError,
        });
      }
    } catch (e) {
      if (retryTimes) {
        await sleep(retryAttempts * 1000);
        return this.sendRequest({
          data,
          method,
          retryTimes: retryTimes - 1,
          retryAttempts: retryAttempts + 1,
          queryParams,
          path,
          headers,
          catchError,
          snackbarError,
          forceError,
        });
      }
      if (snackbarError) {
        const defaultMessage = 'It looks like something went wrong 😢';
        const message = snackbarError !== 'errorMessage' ? defaultMessage : e.response?.data?.message || defaultMessage;
        console.log('snackbar message', message);
      }
      if (!catchError) throw new Error(e);
      return {
        success: false,
        error: e.response?.data?.message || 'request failed',
      };
    }
  };

  verifySession = async () => {
    setRecoil(userLoadingSelector, true);
    if (Cookies.get(SiteCookies.AuthKey)) {
      const res = await this.sendRequest({
        method: 'post',
        path: '/user/verify',
        catchError: true,
      });
      if (res?.success) {
        setRecoil(userDataSelector, res.data);
        setRecoil(userLoadingSelector, false);
      } else {
        setRecoil(userLoadingSelector, false);
        setRecoil(userErrorSelector, res.error);
      }
    } else {
      setRecoil(userLoadingSelector, false);
    }
  };

  login = async ({ email, password }) => {
    setRecoil(userLoadingSelector, true);
    const res = await this.sendRequest({
      method: 'post',
      path: '/user/login',
      catchError: true,
      data: {
        email,
        password,
      },
    });
    if (res?.success) {
      setRecoil(userDataSelector, res.data);
      setRecoil(userLoadingSelector, false);
    } else {
      setRecoil(userLoadingSelector, false);
      setRecoil(userErrorSelector, res.error);
    }
  };

  logout = async () => {
    if (Cookies.get(SiteCookies.AuthKey)) {
      const res = await this.sendRequest({
        method: 'post',
        path: '/user/logout',
        catchError: true,
      });
      if (res?.success) {
        setRecoil(userDataSelector, null);
        setRecoil(userLoadingSelector, false);
        Cookies.remove(SiteCookies.AuthKey);
      }
      return res;
    }
  };

  uploadImage = async ({
    image,
    foldername = 'note',
    title = nanoid(8),
    compress = false,
  }) => {
    let data;
    try {
      if (!image) throw new Error('Image is required');
      const formData = new FormData();
      formData.append('image', image);
      if (title) formData.append('title', title);
      if (foldername) formData.append('foldername', foldername);
      if (compress) formData.append('compress', compress);
      data = await this.sendRequest({
        data: formData,
        method: 'post',
        path: '/image',
        catchError: true,
      });
      if (!data.success) throw new Error(data.message);
      return data;
    } catch (e) {
      console.log(e);
    }
  };
}

const ApiClientInstance = new ApiClient();

ApiClientInstance.initClient();

export default ApiClientInstance;
