import { atom } from 'recoil';
import { DEFAULT_THEME } from '../../styles/themes';

const settingsState = atom({
  key: 'settingsState',
  default: {
    theme: DEFAULT_THEME,
  },
});

export default settingsState;
