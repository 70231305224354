import { useCallback, useMemo } from 'react';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AspectRatioContainer from '../AspectRatioContainer';
import ImageGallery from '../ImageGallery';
import { capitalizeString } from '../../lib/capitalizeString';
import './style.css';

const NoteFieldLabel = ({
  label,
  className = 'note-field-value-label',
}) => (
  <div className={className}>
    { label }
  </div>
);

const Note = ({ note = {}, full }) => {
  const navigate = useNavigate();
  const {
    denomination,
    grading,
    images,
    quantity,
    serial,
    tags,
    catalogue,
    year,
    price,
  } = note;

  // Strings
  const yearRange = useMemo(() => `${year?.min ? `${year?.min}` : ''}${year?.max ? ` - ${year?.max}` : ''}`, [year]);

  const catalogueCombined = useMemo(() => `${catalogue?.prefix ? `${catalogue?.prefix}` : ''}${catalogue?.value ? `${catalogue?.value}` : ''}${catalogue?.suffix ? `${catalogue?.suffix}` : ''}`, [catalogue]);

  const denominationValueToDisplay = useMemo(() => {
    const { value, displayValue } = denomination;
    if (!value && !displayValue) return '';
    return displayValue || value;
  }, [denomination.value, denomination?.displayValue]);

  const titleString = useMemo(() => {
    try {
      return `${note.country?.name ? note.country?.name.split(' ').map(s => capitalizeString(s)).join(' ') : ''}${catalogueCombined ? ` | p${catalogueCombined}` : ''}
      ${denominationValueToDisplay}${denomination?.value > 1 ? ` ${capitalizeString(denomination.name?.plural)}` : ` ${capitalizeString(denomination.name?.singular)}`}${yearRange ? ` | ${yearRange}` : ''}`;
    } catch (e) {
      return '';
    }
  }, [note?.country, yearRange]);

  const handleViewClick = useCallback(() => {
    if (!note) return;
    navigate(`/note/${note._id}`);
  }, [note?._id]);

  const handleBuyClick = useCallback(() => {
    if (!note) return;
    navigate(`/contact?note=${note._id}`, {
      state: {
        note: {
          ...note,
          catalogueCombined,
        },
      },
    });
  }, [note?._id, catalogueCombined]);

  // Containers
  const titleContainer = useMemo(() => (
    <div className='note-header mb-2 text-lg px-2 tablet:px-4'>
      { titleString }
    </div>
  ), [titleString]);

  const imagesArray = useMemo(() => {
    const arr = [];
    if (images.front) arr.push(images.front);
    if (images.back) arr.push(images.back);
    if (note?.images?.other?.length > 0) arr.push(...note.images.other);
    return arr;
  }, [images]);

  const imagesContainer = useMemo(() => (
    <div className='note-image-wrapper tablet:px-4'>
      { images.front && <ImageGallery images={imagesArray} /> }
      { !images.front && (
      <AspectRatioContainer containerClassName='rounded' imageClassName='rounded' ratio='3:2'>
        <div className='flex items-center justify-center h-[100%] w-[100%] bg-s02dp rounded opacity-75 capitalize'>
          No image available
        </div>
      </AspectRatioContainer>
      ) }
    </div>
  ), [images, titleString]);

  const actionsContainer = useMemo(() => {
    if (full) return null;
    return (
      <div className='note-cta flex justify-center mt-2'>
        <Button
          onClick={handleViewClick}
          variant='contained'
          color='primaryLight'
        >
          View Details
        </Button>
      </div>
    );
  }, [full, handleViewClick]);

  const buyNow = useMemo(() => (
    <div className='note-cta flex justify-center mt-2'>
      <Button
        onClick={handleBuyClick}
        variant='contained'
        color='primary'
        sx={{ width: '127.5px' }}
      >
        Purchase
      </Button>
    </div>
  ), [full, handleBuyClick]);

  const priceContainer = useMemo(() => {
    if (full) return null;
    return (
      <div className='note-price flex justify-center mt-2 font-bold'>
        { `$${price.toFixed(2)}` }
      </div>
    );
  }, [full]);

  const fullNoteBodyContainer = useMemo(() => {
    if (!full) return null;
    return (
      <div className='px-4 py-2'>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <NoteFieldLabel label='country' />
            <span className='capitalize'>{ note.country?.name}</span>
          </Grid>
          <Grid item xs={4}>
            <NoteFieldLabel label='denomination' />
            { denominationValueToDisplay }
          </Grid>
          <Grid item xs={4}>
            <NoteFieldLabel label='pick' />
            { catalogueCombined }
          </Grid>
          <Grid item xs={4}>
            <NoteFieldLabel label='year' />
            { yearRange }
          </Grid>
          <Grid item xs={6} sm={4}>
            <NoteFieldLabel label='Grading Company' />
            { grading?.company?.displayName}
          </Grid>
          <Grid item xs={6} sm={4}>
            <NoteFieldLabel label='Grade' />
            { grading?.grade}
          </Grid>
          <Grid item xs={6} sm={4}>
            <NoteFieldLabel label='Price' />
            { `$${price.toFixed(2)}` }
          </Grid>
        </Grid>
      </div>
    );
  }, [full, note, denominationValueToDisplay]);

  return (
    <div className='note-wrapper bg-surface py-2 rounded w-[100%] tablet:px-2'>
      <div className='note-inner py-3 bg-s01dp rounded'>
        { titleContainer}
        { imagesContainer}
        { priceContainer }
        { actionsContainer }
        { !full && buyNow }
        { fullNoteBodyContainer }
        { full && buyNow }
      </div>
    </div>
  );
};

export default Note;
