import { atom } from 'recoil';
import ApiClientInstance from '../../clients/api';

const denominationsState = atom({
  key: 'denominationsState',
  default: {
    loading: true,
    error: false,
    denominations: [],
  },
  effects: [
    async ({ setSelf, onSet }) => {
      const init = async () => {
        const data = await ApiClientInstance.sendRequest({ method: 'get', path: '/denomination', catchError: true });
        if (data.success) setSelf({ denominations: data.data, loading: false });
        else setSelf({ error: true, loading: false });
      };
      init();
    },
  ],
});

export default denominationsState;
