import { PublishedDateStart } from './constants';

// get list of published dates for the next 6 montths
// it will take a date and return an array of dates of every 2 weeks for the next 6 months
export const getPublishedDateList = (date = PublishedDateStart) => {
  const publishedDates = [];
  const startDate = new Date(date);
  const endDate = new Date(startDate);
  endDate.setMonth(startDate.getMonth() + 6);
  const currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    publishedDates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 14);
  }
  return publishedDates;
};

export const getFirstDateOfEveryMonth = (startDate = new Date()) => {
  const publishedDates = [];
  const endDate = new Date(startDate);
  endDate.setMonth(startDate.getMonth() + 6);
  const currentDate = new Date(startDate);
  if (currentDate.getDate() === 1) {
    publishedDates.push(new Date(currentDate));
  }
  currentDate.setDate(1);
  currentDate.setMonth(currentDate.getMonth() + 1);
  while (currentDate <= endDate) {
    publishedDates.push(new Date(currentDate));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  return publishedDates;
};
