import { useMemo } from 'react';

const childStyle = {
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  objectFit: 'cover',
  position: 'absolute',
};

const AspectRatioContainer = ({
  containerClassName,
  children,
  style,
  onClick,
  ratio = '1:1',
}) => {
  const ratioStyles = useMemo(() => {
    switch (ratio) {
      case '1:1':
        return {
          width: '100%',
          paddingTop: '100%',
        };
      case '4:3':
        return {
          paddingTop: '75%',
        };
      case '3:4':
        return {
          paddingTop: '133.33%',
        };
      case '16:9':
        return {
          paddingTop: '56.25%',
        };
      case '21:9':
        return {
          paddingTop: '42.857142857142854%',
        };
      case '3:2':
        return {
          paddingTop: '66.66666667%',
        };
      case '5:4':
        return {
          paddingTop: '133.33333333%',
        };
      default:
        return {
          width: '100%',
          paddingTop: '100%',
        };
    }
  }, [ratio]);

  if (onClick) {
    return (
      <div
        role='button'
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
        className={`flex items-center justify-center${containerClassName ? ` ${containerClassName}` : ''}`}
        style={{
          ...style,
          width: '100%',
          position: 'relative',
          ...ratioStyles,
        }}
      >
        <div style={childStyle}>{ children }</div>
      </div>
    );
  }

  return (
    <div
      className={`flex items-center justify-center${containerClassName ? ` ${containerClassName}` : ''}`}
      style={{
        ...style,
        width: '100%',
        position: 'relative',
        ...ratioStyles,
      }}
    >
      <div style={childStyle}>{ children }</div>
    </div>
  );
};

export default AspectRatioContainer;
