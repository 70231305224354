export const mapTheme = (variables) => ({
  '--color-primary': variables.primary || '',
  '--color-primary-light': variables.primaryLight || '',
  '--color-primary-dark': variables.primaryDark || '',
  '--color-secondary': variables.secondary || '',
  '--color-secondary-light': variables.secondaryLight || '',
  '--color-secondary-dark': variables.secondaryDark || '',
  '--color-text-primary': variables.textPrimary || '',
  '--color-surface': variables.surface || '',
  '--color-error': variables.error || '',
  '--color-success': variables.success || '',
  '--color-s001dp': variables.s001dp || '',
  '--color-s0015dp': variables.s0015dp || '',
  '--color-s002dp': variables.s002dp || '',
  '--color-s0025dp': variables.s0025dp || '',
  '--color-s003dp': variables.s003dp || '',
  '--color-s01dp': variables.s01dp || '',
  '--color-s02dp': variables.s02dp || '',
  '--color-s03dp': variables.s03dp || '',
  '--color-s04dp': variables.s04dp || '',
  '--color-s06dp': variables.s06dp || '',
  '--color-s08dp': variables.s08dp || '',
  '--color-s12dp': variables.s12dp || '',
  '--color-s16dp': variables.s16dp || '',
  '--color-s24dp': variables.s24dp || '',
  '--color-disabled': variables.disabled || '',
  '--color-disabled-text': variables.disabledText || '',
});
