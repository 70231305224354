import {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { TextField, Button } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import {
  Content,
  Wrapper,
} from '../../components/Containers';
import { breakpointNameSelector } from '../../models/settings/selector';
import { Breakpoints } from '../../lib/constants';
import ApiClientInstance from '../../clients/api';

const Contact = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const location = useLocation();

  const note = useMemo(() => location?.state?.note, [location]);

  useEffect(() => {
    if (!note) return;
    const {
      country, _id, catalogueCombined, grading,
    } = note;
    const messageString = `I'm interested in purchasing the note with the following details: \n\nNoteId: ${_id}\nCountry: ${country?.name}\nCatalogue Number: ${catalogueCombined}\nGrade: ${grading.grade}\n\nPlease contact me at your earliest convenience. Thank you!`;
    setMessage(messageString);
  }, [note]);

  const breakpointName = useRecoilValue(breakpointNameSelector);

  const isMobile = breakpointName === Breakpoints.Mobile;

  const submit = useCallback(async () => {
    if (!email || !message) return;
    const res = await ApiClientInstance.sendRequest({
      method: 'POST',
      data: {
        firstName,
        lastName,
        email,
        phone,
        message,
      },
      path: '/contact-submission',
      catchError: true,
    });
    if (res?.success) {
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('');
      setMessage('');
    }
  }, [firstName, lastName, email, phone, message]);

  const firstNameField = useMemo(() => (
    <div className='contact_first-name'>
      <div className='filter-group-label'>First Name</div>
      <TextField fullWidth size='small' value={firstName} onChange={e => setFirstName(e.target.value)} />
    </div>
  ), [firstName, isMobile]);

  const lastNameField = useMemo(() => (
    <div className='contact_last-name'>
      <div className='filter-group-label'>Last Name</div>
      <TextField fullWidth size='small' value={lastName} onChange={e => setLastName(e.target.value)} />
    </div>
  ), [lastName, isMobile]);

  const emailField = useMemo(() => (
    <div className='contact_email'>
      <div className='filter-group-label'>Email (required)</div>
      <TextField fullWidth={isMobile} required size='small' value={email} onChange={e => setEmail(e.target.value)} />
    </div>
  ), [email, isMobile]);

  // const phoneField = useMemo(() => (
  //   <div className='contact_phone'>
  //     <div className='filter-group-label'>Phone</div>
  //     <TextField fullWidth={isMobile} size='small' value={phone} onChange={e => setPhone(e.target.value)} />
  //   </div>
  // ), [phone, isMobile]);

  const messageField = useMemo(() => (
    <div className='contact_message'>
      <div className='filter-group-label'>Message (required)</div>
      <TextField multiline fullWidth value={message} onChange={e => setMessage(e.target.value)} />
    </div>
  ), [message, isMobile]);

  const submitSection = useMemo(() => (
    <div className='contact_submit py-4 flex justify-center'>
      <Button onClick={submit} variant='contained' style={{ minWidth: '200px' }} fullWidth={isMobile}>Send</Button>
    </div>
  ), [submit, firstName, lastName, email, phone, message, isMobile]);

  return (
    <Wrapper applyBreakpoints className='contact'>
      <Content style={{ height: '100%' }} className='px-2'>
        <h1 className='py-2 text-[36px] font-bebas text-center'>Contact Us</h1>
        <p className='text-lg mb-4'>To purchase notes please send an email below including the Country, P# and Grade and we will provide payment instructions.</p>
        { isMobile && (
          <>
            { firstNameField }
            { lastNameField }
            { emailField }
          </>
        )}
        { !isMobile && (
          <div className='flex space-x-3 w-[600px]'>
            { firstNameField }
            { lastNameField }
            { emailField }
          </div>
        )}

        {/* { phoneField } */}
        { messageField }
        { submitSection }
      </Content>
    </Wrapper>
  );
};

export default Contact;
