import { selector } from 'recoil';
import { Breakpoints } from '../../lib/constants';
import state from './atom';

export const themeSelector = selector({
  key: 'themeSelector',
  get: ({ get }) => get(state).theme,
  set: ({ set, get }, newValue) => set(state, { ...get(state), theme: newValue }),
});

export const breakpointNameSelector = selector({
  key: 'breakpointNameSelector',
  get: ({ get }) => get(state).breakpointName,
  set: ({ set, get }, newValue) => {
    const newBreakpoint = Object.values(Breakpoints).find(bp => bp === newValue);
    if (newBreakpoint) {
      set(state, { ...get(state), breakpointName: newBreakpoint });
    }
  },
});
