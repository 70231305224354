import { useEffect, useState } from 'react';
import protectedRequirements from '../../lib/protectedRequirements';
import { useAuthenticatedUser } from '../../models/user/useAuthenticatedUser';

const Protected = ({ children, requirements }) => {
  const [isAllowed, setIsAllowed] = useState(false);
  const {
    data: user,
    unauthenticated,
  } = useAuthenticatedUser();

  useEffect(() => {
    setIsAllowed(protectedRequirements({ user, requirements, unauthenticated }));
  }, [user, requirements]);

  if (unauthenticated) return null;

  if (!requirements) return children;

  if (!isAllowed) return null;

  return children;
};

export default Protected;
