import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';

const Navbar = () => {
  const handleMenuClick = useCallback(() => emitCustomEvent(CustomEvents.OpenSiteMenu), []);

  return (
    <nav className='w-full primary bg-surface h-12 wpc-box-shadow z-10 text-primary-text'>
      <div className='flex items-center justify-between desktop:w-[992px] desktop:ml-auto desktop:mr-auto relative h-[48px] font-bebas text-[24px] pl-2'>
        <Link to='/' className='flex items-center justify-between'>
          World Bank Note Sales
        </Link>
        <IconButton
          onClick={handleMenuClick}
        >
          <MenuIcon />
        </IconButton>
      </div>
    </nav>
  );
};

export default Navbar;
