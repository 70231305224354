import { useCallback, useMemo, useState } from 'react';
import { Drawer } from '@mui/material';

import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import EmailIcon from '@mui/icons-material/Email';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import buildList from '../../lib/buildList';
import { CustomEvents, UserRoles } from '../../lib/constants';
import { emitCustomEvent, useCustomEvent } from '../../hooks/useCustomEventListener';
import { useAuthenticatedUser } from '../../models/user/useAuthenticatedUser';
import ApiClientInstance from '../../clients/api';

const SiteMenu = () => {
  const navigate = (path) => emitCustomEvent(CustomEvents.Redirect, { path });
  const [open, setOpen] = useState(false);
  const { data: user, loading, unauthenticated } = useAuthenticatedUser();

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const goToPath = useCallback((path) => {
    // TODO: verify this is a good experience across all browswers and connections
    // setting timeout to show click ripple
    setTimeout(() => {
      handleClose();
      navigate(path);
    }, 100);
  }, []);

  const logout = useCallback(async () => {
    handleClose();
    const res = await ApiClientInstance.logout();
    console.log(res);
    if (res?.success) navigate('/');
  }, []);

  useCustomEvent(CustomEvents.OpenSiteMenu, handleOpen, []);

  const list = useMemo(() => {
    const listData = [
      {
        handler: () => goToPath('/'),
        Icon: HomeIcon,
        text: 'Home',
      },
      {
        text: 'About',
        Icon: PeopleAltIcon,
        handler: () => goToPath('/About'),
      },
      {
        text: 'Contact',
        Icon: EmailIcon,
        handler: () => goToPath('/contact'),
      },
      {
        exclude: !!user,
        handler: () => goToPath('/login'),
        Icon: LoginIcon,
        text: 'Login',
      },
      {
        isProtected: true,
        requirements: { roles: [UserRoles.SuperAdmin, UserRoles.Admin] },
        handler: () => goToPath('/admin'),
        Icon: AdminPanelSettingsIcon,
        text: 'Admin',
      },
      {
        exclude: !user,
        handler: logout,
        Icon: LogoutIcon,
        text: 'Logout',
      },
    ];
    if (!listData.length) return null;
    return buildList({ listData });
  }, [JSON.stringify(user), loading, unauthenticated]);

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose}
    >
      <div className='site-nav-wrapper w-[250px] tablet:w-[300px]'>
        <div className='site-nav-inner-wrapper px-3 py-3'>
          <div className='flex text-[30px] font-bebas justify-center'>World Bank Note Sales</div>
          { list }
        </div>
      </div>
    </Drawer>
  );
};

export default SiteMenu;
