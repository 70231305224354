import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticatedUser } from '../models/user/useAuthenticatedUser';
import protectedRequirements from '../lib/protectedRequirements';

const useProtectedRequirements = (requirements, redirectUrl = '/') => {
  const { data: user, unauthenticated, loading } = useAuthenticatedUser();
  const navigate = useNavigate();
  let isAllowed;
  useEffect(() => {
    isAllowed = protectedRequirements({ requirements, user, unauthenticated });
    if (!isAllowed && !loading) {
      navigate(redirectUrl);
    }
  }, [loading, user, unauthenticated]);
  return { loading, isAllowed };
};

export default useProtectedRequirements;
