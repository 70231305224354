const NoteEditLabel = ({
  label,
  hasError,
  errorText = 'required',
  className = 'note-edit-value-label',
  modified,
}) => (
  <div className={`${className}${(modified && !hasError) ? ' text-primary-dark' : ''}${hasError ? ' text-error' : ''}`}>
    { label }
    { modified && !hasError ? '*' : '' }
    { hasError && <span className='text-error text-sm'>{ ` ${errorText}` }</span> }
  </div>
);

export default NoteEditLabel;
