import { Routes, Route } from 'react-router-dom';
import useProtectedRequirements from '../../hooks/useProtectedRequirements';
import { Wrapper, Content } from '../../components/Containers';
import { UserRoles } from '../../lib/constants';
import NotFound from '../NotFound';
import AdminInventory from '../../components/AdminInventory';
import AdminEditNoteModal from '../../components/AdminEditNoteModal';

const Admin = () => {
  const { loading } = useProtectedRequirements({ roles: [UserRoles.Admin, UserRoles.SuperAdmin] });
  if (loading) return null;
  return (
    <>
      <Wrapper>
        <Content>
          <Wrapper applyBreakpoints>
            <Content>
              <Routes>
                <Route path='/' element={<AdminInventory />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
            </Content>
          </Wrapper>
        </Content>
      </Wrapper>
      <AdminEditNoteModal />
    </>
  );
};

export default Admin;
