/* eslint-disable react/jsx-props-no-spreading */
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ListItemButton, Chip } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { gradingCompaniesSelector } from '../../models/gradingCompanies/selector';

const GradingCompanyOption = ({
  displayName,
  muiProps,
}) => {
  const isSelected = muiProps?.['aria-selected'];

  return (
    <ListItemButton selected={isSelected} {...muiProps} className='py-1 flex items-center'>
      <span className='ml-2 text-sm'>{displayName}</span>
    </ListItemButton>
  );
};

const GradingCompanySelect = ({
  disabled,
  gradingCompanies,
  gradingCompany,
  setGradingCompany,
  setGradingCompanies,
  placeholder = 'select a grading company',
  label = 'select a grading company',
  sx = {},
  size,
  selectType,
  style,
  className,
}) => {
  const options = useRecoilValue(gradingCompaniesSelector);

  const value = gradingCompany || '';

  const isOptionEqualToValue = useCallback((option, val) => {
    if (!val?.displayName) return val === '';
    return option?.displayName === val?.displayName;
  }, []);

  const getOptionLabel = useCallback((option) => option?.displayName || '', []);

  const renderOption = useCallback((props, option) => <GradingCompanyOption key={option._id} displayName={option?.displayName} muiProps={props} />, []);

  const renderInput = useCallback((params) => <TextField {...params} label={label} />, []);

  const handleMultipleOnChange = useCallback((event, newValue) => setGradingCompanies(newValue), [setGradingCompanies]);

  const handleSingleOnChange = useCallback((event, newValue) => setGradingCompany(newValue), [setGradingCompany]);

  const renderTags = useCallback((_value, getTagProps) => {
    if (!_value) return null;
    return _value.map((option, index) => (
      <Chip
        color='primary'
        label={option.displayName}
        size='small'
        {...getTagProps({ index })}
      />
    ));
  }, []);

  if (selectType === 'multiple') {
    return (
      <Autocomplete
        className={`${className} bg-s01dp`}
        multiple
        sx={style}
        noOptionsText='no grading companies found'
        options={options}
        onChange={handleMultipleOnChange}
        value={gradingCompanies}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        defaultValue={gradingCompanies}
        renderOption={renderOption}
        renderTags={renderTags}
        renderInput={renderInput}
        size={size || ''}
        ListboxProps={{ sx: { backgroundColor: 'var(--color-s04dp)' } }}
      />
    );
  }

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      ListboxProps={{ className: 'h-[200px] tablet:h-[200px] desktop:h-[200px] bg-s02dp' }}
      className='bg-s01dp mt-2'
      disablePortal
      placeholder={placeholder}
      id='grading-company-search'
      options={options}
      sx={sx}
      value={value}
      size={size || ''}
      onChange={handleSingleOnChange}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={renderInput}
    />
  );
};

export default GradingCompanySelect;
