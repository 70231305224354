import { useCallback, useMemo, useState } from 'react';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';

const Image = ({
  onClick,
  imageModal,
  className = '',
  style = {},
  src,
  alt,
  onError,
  onLoad,
}) => {
  const [hiddenByError, setHidden] = useState(false);
  const [hasLoaded, setLoaded] = useState(false);

  const handleError = useCallback(() => {
    if (onError) onError();
    setHidden(true);
  }, [onError]);

  const handleLoad = useCallback(() => {
    if (hasLoaded) return;
    if (onLoad) onLoad();
    setLoaded(true);
  }, [onLoad, hasLoaded]);

  const _style = useMemo(() => {
    if (hasLoaded) return {};
    return { visibility: 'hidden' };
  }, [hasLoaded]);

  const handleClick = useCallback(() => {
    if (!onClick && !imageModal) return;
    if (onClick) onClick();
    if (imageModal) emitCustomEvent(CustomEvents.OpenImageModal, { url: src, text: alt });
  }, [onClick, imageModal, src, alt]);

  const content = useMemo(() => {
    if (onClick || imageModal) {
      return (
        <div
          role='button'
          tabIndex={0}
          onKeyDown={handleClick}
          onClick={handleClick}
        >
          <img
            onError={handleError}
            onLoad={handleLoad}
            src={!hiddenByError ? src : 'https://media3.giphy.com/media/3zhxq2ttgN6rEw8SDx/giphy.gif'}
            alt={alt}
            className={className ? `${className}` : ''}
            style={{ ...style, ..._style }}
          />
        </div>
      );
    }

    return (
      <img
        onError={handleError}
        onLoad={handleLoad}
        src={!hiddenByError ? src : 'https://media3.giphy.com/media/3zhxq2ttgN6rEw8SDx/giphy.gif'}
        alt={alt}
        className={className ? `${className}` : ''}
        style={{ ...style, ..._style }}
      />
    );
  }, [src, hasLoaded, hiddenByError, onClick]);

  return content;
};

export default Image;
