import {
  Routes,
  Route,
} from 'react-router-dom';

import { Content } from '../Containers';

import Home from '../../pages/Home';
import NotFound from '../../pages/NotFound';
import ErrorBoundary from '../ErrorBoundary';
import Navbar from '../Navbar';
import Footer from '../Footer';

import { useApplyTheme } from '../../hooks/useApplyTheme';
import NoteDetail from '../../pages/NoteDetail';
import HooksAndAnalytics from '../HooksAndAnalytics';
import ImageModal from '../ImageModal';
import SiteMenu from '../SiteMenu';
import Login from '../../pages/Login';
import RedirectListener from '../RedirectListener';
import Admin from '../../pages/Admin';
import Contact from '../../pages/Contact';
import About from '../../pages/About';

const wrapWithErrorBoundary = (Component) => <ErrorBoundary redirect='/404'><Component /></ErrorBoundary>;

const MainRouter = () => {
  useApplyTheme();

  return (
    <>
      <Navbar />
      <Content className='wpc-main-content'>
        <Routes>
          <Route path='/' element={wrapWithErrorBoundary(Home)} />
          <Route path='/note/:noteId' element={wrapWithErrorBoundary(NoteDetail)} />
          <Route path='/login' element={wrapWithErrorBoundary(Login)} />
          <Route path='/404' element={<NotFound />} />
          <Route path='/contact' element={wrapWithErrorBoundary(Contact)} />
          <Route path='/about' element={wrapWithErrorBoundary(About)} />
          <Route path='/admin/*' element={wrapWithErrorBoundary(Admin)} />
          <Route path='*' element={wrapWithErrorBoundary(NotFound)} />
        </Routes>
      </Content>
      <Footer />
      <HooksAndAnalytics />
      <ImageModal />
      <SiteMenu />
      <RedirectListener />
    </>
  );
};

export default MainRouter;
