import { selector } from 'recoil';
import state from './atom';

export const denominationsSelector = selector({
  key: 'denominationsSelector',
  get: ({ get }) => {
    const denominationsState = get(state);
    return denominationsState.denominations;
  },
});
