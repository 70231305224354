import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { useCallback, useState } from 'react';
import { useCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '6px',
  p: 4,
  display: 'flex',
  minWidth: '300px',
  justifyContent: 'center',
  alignItems: 'center',
};

const ImageModal = ({
  styles,
}) => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [altText, setAltText] = useState('');

  const handleEvent = useCallback(({ url: _url, text }) => {
    if (!_url) return;
    setUrl(_url);
    setAltText(text || _url);
    setOpen(true);
  }, []);

  useCustomEvent(CustomEvents.OpenImageModal, handleEvent);

  return (
    <Modal
      className='tku-no-select'
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='image-container'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles || modalStyle} className='tku-no-select'>
        <IconButton
          className='tku-no-select'
          size='large'
          onClick={() => setOpen(false)}
          type='button'
          style={{
            position: 'absolute',
            top: '0px',
            right: '5px',
            opacity: '.7',
          }}
        >
          <CancelIcon />
        </IconButton>
        <img style={{ minHeight: '100px', maxHeight: '90vh', maxWidth: '90vw' }} className='rounded my-2' src={url} alt={altText} />
      </Box>
    </Modal>
  );
};

export default ImageModal;
