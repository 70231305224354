/* eslint-disable react/jsx-props-no-spreading */
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ListItemButton, Chip } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { countriesSelector } from '../../models/countries/selector';

const CountryOption = ({
  name,
  muiProps,
}) => {
  const isSelected = muiProps?.['aria-selected'];
  return (
    <ListItemButton selected={isSelected} {...muiProps} className='py-1 flex items-center'>
      <span className='ml-2 text-sm'>{name}</span>
    </ListItemButton>
  );
};

const CountrySelect = ({
  disabled,
  country,
  countries,
  setCountry,
  setCountries,
  placeholder = 'select a country',
  label = 'select a country',
  sx = {},
  size,
  selectType,
  style,
  className,
}) => {
  const options = useRecoilValue(countriesSelector);

  const value = country || '';

  const isOptionEqualToValue = useCallback((option, val) => {
    if (!val?.name) return val === '';
    return option?.name === val?.name;
  }, []);

  const handleMultipleOnChange = useCallback((event, newValue) => setCountries(newValue), [setCountries]);

  const getOptionLabel = useCallback((option) => option?.name || '', []);

  const renderTags = useCallback((_value, getTagProps) => {
    if (!_value) return null;
    return _value.map((option, index) => (
      <Chip
        color='primary'
        label={option.name}
        size='small'
        {...getTagProps({ index })}
      />
    ));
  }, []);

  const renderOption = useCallback((props, option) => <CountryOption key={option._id} name={option?.name} color={option?.color} image={option?.image} muiProps={props} />, []);

  const renderInput = useCallback((params) => <TextField {...params} label={label} />, []);

  const handleSingleOnChange = useCallback((event, newValue) => setCountry(newValue), [setCountry]);

  if (selectType === 'multiple') {
    return (
      <Autocomplete
        className={`${className} bg-s01dp`}
        multiple
        sx={style}
        noOptionsText='no denominations found'
        options={options}
        onChange={handleMultipleOnChange}
        value={countries}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        defaultValue={countries}
        renderOption={renderOption}
        renderTags={renderTags}
        renderInput={renderInput}
        ListboxProps={{ sx: { backgroundColor: 'var(--color-s04dp)' } }}
        size={size || ''}
      />
    );
  }

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      ListboxProps={{ className: 'h-[200px] tablet:h-[300px] desktop:h-[400px] bg-s02dp' }}
      className='bg-s01dp mt-2'
      disablePortal
      placeholder={placeholder}
      id='category-search'
      options={options}
      sx={sx}
      value={value}
      size={size || ''}
      onChange={handleSingleOnChange}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={renderInput}
    />
  );
};

export default CountrySelect;
