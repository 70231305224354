import { Card } from '@mui/material';

const ErrorCard = ({ message }) => (
  <article className='topic-card tku-no-select font-roboto flex space-y-2 py-1 px-1 text-primary-text w-[100%] items-stretch'>
    <Card sx={{ padding: '5px 8px', flex: 1, background: 'var(--color-s01dp)' }}>
      <div className='space-y-2 py-4 px-1 flex items-center justify-center w-[100%] h-[100%]'>
        <span className='leading-0 text-sm'>{message || 'an error has occurred'}</span>
      </div>
    </Card>
  </article>
);

export default ErrorCard;
