import { selector } from 'recoil';
import state from './atom';

export const userLoadingSelector = selector({
  key: 'userLoadingSelector',
  get: ({ get }) => get(state).loading,
  set: ({ set, get }, newValue) => set(state, { ...get(state), loading: newValue }),
});

export const userErrorSelector = selector({
  key: 'userErrorSelector',
  get: ({ get }) => get(state).error,
  set: ({ set, get }, newValue) => set(state, { ...get(state), error: newValue }),
});

export const userDataSelector = selector({
  key: 'userDataSelector',
  get: ({ get }) => get(state).data,
  set: ({ set, get }, newValue) => set(state, { ...get(state), data: newValue }),
});

export const unauthenticatedSelector = selector({
  key: 'unauthenticatedSelector',
  get: ({ get }) => {
    const { data, loading } = get(state);
    return !data && !loading;
  },
});
