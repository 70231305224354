import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button } from '@mui/material';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';
import NoteListFilterModal from '../NoteListFilterModal';

const handleFilterClick = ({ atomKey, filterOptions }) => emitCustomEvent(CustomEvents.OpenNoteFilterModal, { atomKey, options: filterOptions });

const NoteListHeader = ({
  atomKey,
  title,
  FilterComponent,
  filterOptions,
  HeaderButton,
}) => (
  <div className='flex justify-center font-bebas text-[22px] text-primary-text'>
    <div className='px-2 py-2 w-[992px] flex items-center justify-between'>
      <div className='flex items-center justify-center space-x-3'>
        <div>{ title || 'Notes'}</div>
        { HeaderButton && <HeaderButton /> }
      </div>
      { FilterComponent && (
      <>
        <Button
          onClick={() => handleFilterClick({ atomKey, filterOptions })}
          startIcon={<FilterAltIcon sx={{ height: '25px' }} />}
          variant='contained'
          size='medium'
        >
          Sort/Filter
        </Button>
        <NoteListFilterModal />
      </>
      ) }
    </div>
  </div>
);

export default NoteListHeader;
