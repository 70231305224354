/* eslint-disable react/jsx-props-no-spreading */
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ListItemButton, Chip } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { denominationsSelector } from '../../models/denominations/selector';

const DenominationOption = ({
  singular,
  plural,
  muiProps,

}) => {
  const isSelected = muiProps?.['aria-selected'];
  return (
    <ListItemButton selected={isSelected} {...muiProps} className='py-1 flex items-center'>
      <span className='ml-2 text-sm'>{`${singular}/${plural}`}</span>
    </ListItemButton>
  );
};

const DenominationSelect = ({
  disabled,
  denomination,
  denominations,
  setDenominations,
  setDenomination,
  placeholder = 'select a denomination',
  label = 'select a denomination',
  sx = {},
  size,
  selectType,
  className,
  style,
  error,
}) => {
  const options = useRecoilValue(denominationsSelector);

  const value = denomination || '';

  const isOptionEqualToValue = useCallback((option, val) => {
    if (!val?._id) return val === '';
    return option?._id === val?._id;
  }, []);

  const handleMultipleOnChange = useCallback((event, newValue) => setDenominations(newValue), [setDenominations]);

  const handleSingleOnChange = useCallback((event, newValue) => setDenomination(newValue), [setDenomination]);

  const getOptionLabel = useCallback((option) => ((option?.singular && option?.plural) ? `${option?.singular}/${option?.plural}` : ''), []);

  const renderOption = useCallback((props, option) => <DenominationOption key={option._id} _id={option?._id} singular={option?.singular} plural={option?.plural} muiProps={props} />, []);
  const renderInput = useCallback((params) => <TextField {...params} label={label} />, []);

  const renderTags = useCallback((_value, getTagProps) => {
    if (!_value) return null;
    return _value.map((option, index) => (
      <Chip
        color='primary'
        label={option.plural}
        size='small'
        {...getTagProps({ index })}
      />
    ));
  }, []);

  if (selectType === 'multiple') {
    return (
      <Autocomplete
        className={`${className} bg-s01dp`}
        multiple
        sx={style}
        noOptionsText='no denominations found'
        options={options}
        onChange={handleMultipleOnChange}
        value={denominations}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        defaultValue={denominations}
        renderOption={renderOption}
        renderTags={renderTags}
        renderInput={renderInput}
        ListboxProps={{ sx: { backgroundColor: 'var(--color-s04dp)' } }}
        size={size || ''}
      />
    );
  }

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      ListboxProps={{ className: 'h-[200px] tablet:h-[200px] desktop:h-[200px] bg-s02dp' }}
      className='bg-s01dp mt-2'
      disablePortal
      placeholder={placeholder}
      id='denomination-search'
      options={options}
      sx={sx}
      value={value}
      size={size || ''}
      onChange={handleSingleOnChange}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={renderInput}
    />
  );
};

export default DenominationSelect;
