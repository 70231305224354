import { forwardRef } from 'react';

const Content = forwardRef(({
  children,
  className,
  style,
  onLoad,
}, ref) => (
  <div
    onLoad={onLoad}
    style={{ ...style, boxSizing: 'content-box' }}
    ref={ref}
    className={`wpc-content ${className || ''}`}
  >
    { children }
  </div>
));

export default Content;
