import { Content, Wrapper } from '../../components/Containers';
import NoteList from '../../components/NoteList';
import NoteListFilter from '../../components/NoteListFilter';
import NoteListHeader from '../../components/NoteListHeader';

const atomKey = 'home';

const FilterComponent = () => <NoteListFilter atomKey={atomKey} />;

const TotalItemsComponent = ({ totalItems }) => (
  <div className='py-2 px-2 font-semibold italic'>
    {`${totalItems} ${totalItems === 1 ? 'note' : 'notes'} found`}
  </div>
);

const Home = () => (
  <Wrapper applyBreakpoints className='home'>
    <Content style={{ height: '100%' }} className='wpc-note-list'>
      <NoteListHeader title='Browse Notes' FilterComponent={FilterComponent} atomKey={atomKey} />
      <NoteList
        atomKey={atomKey}
        customScrollParentSelector='.wpc-note-list'
        showTotalItems
        TotalItemsComponent={TotalItemsComponent}
      />
    </Content>
  </Wrapper>
);

export default Home;
