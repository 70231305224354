import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Content, Wrapper } from '../../components/Containers';
import Note from '../../components/Note';
import ApiClientInstance from '../../clients/api';
import CoinLoader from '../../components/Loaders/CoinLoader';

const fetchNote = async (id) => {
  const noteRes = await ApiClientInstance.sendRequest({
    method: 'GET',
    path: `/note/${id}`,
    catchError: true,
  });
  return noteRes;
};

const NoteDetail = () => {
  const [note, setNote] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { noteId } = useParams();
  const navigate = useNavigate();

  useEffect(async () => {
    if (!noteId) return navigate('/');
    setLoading(true);
    const noteRes = await fetchNote(noteId);
    if (noteRes.success) setNote(noteRes.data);
    else return navigate('/');
    setLoading(false);
  }, []);

  return (
    <Wrapper applyBreakpoints className='home'>
      <Content>
        { loading && <CoinLoader boxType='fullContainer' />}
        { note && <Note full note={note} /> }
      </Content>
    </Wrapper>
  );
};
export default NoteDetail;
