import { nanoid } from 'nanoid';
import {
  Button,
} from '@mui/material';
import AspectRatioImage from '../AspectRatioImage';
import AspectRatioContainer from '../AspectRatioContainer';
import { useUploadOnFileChange } from '../../hooks/useUploadOnFileChange';
import ImageInput from '../ImageInput';
import NoteEditLabel from '../AdminEditNoteLabel';

const ImageContainer = ({
  isAdditional,
  setter,
  image,
  label,
  alt,
  remove,
  replace,
  noteId,
  originalImage,
  modified,
}) => {
  const handleUpload = useUploadOnFileChange();

  const uploadParams = {
    foldername: 'note',
    title: `${label}-${noteId || nanoid(8)}`,
    callback: isAdditional ? ({ url, compressed }) => replace(compressed.url, url) : ({ url, compressed }) => setter(compressed.url, url),
    compress: true,
  };

  const handleUploadImage = handleUpload(uploadParams);

  return (
    <>
      <NoteEditLabel label={label} modified={modified} />
      <div className='note-edit-image-container'>
        { !image && (
          <AspectRatioContainer containerClassName='rounded' imageClassName='rounded' ratio='3:2'>
            <div className='flex items-center justify-center h-[100%] w-[100%] bg-s02dp rounded'>
              No image
            </div>
          </AspectRatioContainer>
        ) }
        { image && <AspectRatioImage ratio='3:2' src={image} alt={alt} imageModal /> }
        <div className='space-x-2 flex justify-end mt-2'>
          <Button
            size='small'
            variant='outlined'
            onClick={!isAdditional ? () => setter('', '') : () => remove(image, originalImage)}
            disabled={!image}
          >
            Remove
          </Button>
          <ImageInput handleChange={handleUploadImage} id={label}>
            <Button
              size='small'
              variant='contained'
              component='span'
            >
              {image ? 'Change' : 'Upload'}
            </Button>
          </ImageInput>
        </div>
      </div>
    </>
  );
};

export default ImageContainer;
