import { atomFamily } from 'recoil';

export const INITIAL_FILTER_STATE = {
  country: [],
  denomination: [],
  value: {
    min: '',
    max: '',
  },
  gradingCompany: [],
  grade: {
    min: '',
    max: '',
  },
  price: {
    min: '',
    max: '',
  },
  year: {
    min: '',
    max: '',
  },
  quantity: {
    min: '',
    max: '',
  },
  catalogue: {
    prefix: '',
    value: {
      min: '',
      max: '',
    },
    suffix: '',
  },
  archived: false,
  noImages: false,
  published: true,
  unpublished: true,
  tags: [],
  sort: [],
};

const noteListSettingsState = atomFamily({
  key: 'noteListSettingsState',
  default: {
    ...INITIAL_FILTER_STATE,
  },
  effects: param => [
    ({ onSet, setSelf }) => {
      if (param.includes('admin')) {
        setSelf({
          ...INITIAL_FILTER_STATE,
          quantity: {
            min: 0,
            max: '',
          },
        });
      }
    },
  ],
});

export default noteListSettingsState;
