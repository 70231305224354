import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';

export const queryClient = new QueryClient();

const QueryProvider = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    { children }
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>
);

export default QueryProvider;
