import { useRecoilValue } from 'recoil';
import { useEffect, useMemo, useState } from 'react';
import SimpleItemList from '../SimpleItemList';
import Note from '../Note';
import { Breakpoints, UserRoles, VirtuosoTypes } from '../../lib/constants';
import { breakpointNameSelector } from '../../models/settings/selector';
import noteListSettingsState from '../../models/noteListSettings/atom';
import { parseIntSafe } from '../../lib/parseIntSafe';
import { useAuthenticatedUser } from '../../models/user/useAuthenticatedUser';

const NoteComponent = ({ item, index }) => (
  <Note note={item} />
);

const NoteList = ({
  ItemComponent = NoteComponent,
  listType,
  atomKey = 'home',
  customScrollParentSelector,
  showTotalItems,
  TotalItemsComponent,
}) => {
  const breakpointName = useRecoilValue(breakpointNameSelector);
  const filters = useRecoilValue(noteListSettingsState(atomKey));
  const [customScrollParent, setCustomScrollParent] = useState(null);
  const { loading: authloading, authenticated, data: user } = useAuthenticatedUser();

  const queryParams = useMemo(() => {
    const params = {};
    if (filters?.denomination?.length) params.denomination = filters.denomination.map(d => d?._id);
    if (filters?.country?.length) params.country = filters.country.map(d => d?._id);
    if (filters?.gradingCompany?.length) params.gradingCompany = filters.gradingCompany.map(d => d?._id);
    if (parseIntSafe(filters?.value?.min)) params.valueMin = parseIntSafe(filters.value.min);
    if (parseIntSafe(filters?.value?.max)) params.valueMax = parseIntSafe(filters.value.max);
    if (parseIntSafe(filters?.price?.min)) params.priceMin = parseIntSafe(filters.price.min);
    if (parseIntSafe(filters?.price?.max)) params.priceMax = parseIntSafe(filters.price.max);
    if (parseIntSafe(filters?.grade?.max)) params.gradeMax = parseIntSafe(filters.grade.max);
    if (parseIntSafe(filters?.grade?.min)) params.gradeMin = parseIntSafe(filters.grade.min);
    if (parseIntSafe(filters?.year?.min)) params.yearMin = parseIntSafe(filters.year.min);
    if (parseIntSafe(filters?.year?.max)) params.yearMax = parseIntSafe(filters.year.max);
    if (parseIntSafe(filters?.quantity?.min, -1) > -1) params.quantityMin = parseIntSafe(filters.quantity.min);
    if (parseIntSafe(filters?.quantity?.max, -1) > -1) params.quantityMax = parseIntSafe(filters.quantity.max);
    if (filters.sort?.length) params.sort = JSON.stringify(filters.sort.map(s => s?.value));
    params.published = true;
    if (!authloading && [UserRoles.Admin, UserRoles.SuperAdmin].includes(user?.role) && atomKey.includes('admin')) {
      params.archived = filters.archived;
      params.noImages = filters.noImages;
      params.published = filters.published;
      params.unpublished = filters.unpublished;
    }
    return params;
  }, [filters, authloading, user?.role]);

  const _listType = useMemo(() => {
    if (listType && Object.values(VirtuosoTypes).includes(listType)) return listType;
    return breakpointName === Breakpoints.Mobile ? VirtuosoTypes.List : VirtuosoTypes.Grid;
  }, [breakpointName, listType]);

  useEffect(() => {
    if (!customScrollParent) {
      const scrollParent = document.querySelector(customScrollParentSelector);
      if (scrollParent) setCustomScrollParent(scrollParent);
    }
  }, [customScrollParentSelector]);

  return (
    <SimpleItemList
      TotalItemsComponent={TotalItemsComponent}
      showTotalItems={showTotalItems}
      customScrollParent={(customScrollParent || null)}
      path='/note'
      sort={null}
      isEnabled={!authloading}
      showErrorMessage
      showTopButton={!authloading}
      ItemComponent={ItemComponent}
      queryParams={{ limit: 50, ...queryParams }}
      virtuosoType={_listType}
    />
  );
};
export default NoteList;
