const surface = '#FFF';
const overlay = '18,18,18';

export default {
  primary: '#E4AB3C', // GOLD
  primaryDark: '#D78A1D',
  primaryLight: '#F3D9A1',
  secondary: '#4886F7',
  secondaryDark: '#2156F1',
  secondaryLight: '#A4C3FB',
  textPrimary: '#121212',
  surface,
  error: '#f44336',
  success: '#66bb6a',
  s001dp: `rgba(${overlay},0.01)`,
  s0015dp: `rgba(${overlay},0.015)`,
  s002dp: `rgba(${overlay},0.02)`,
  s0025dp: `rgba(${overlay},0.025)`,
  s003dp: `rgba(${overlay},0.03)`,
  s01dp: `rgba(${overlay},0.05)`,
  s02dp: `rgba(${overlay},0.07)`,
  s03dp: `rgba(${overlay},0.08)`,
  s04dp: `rgba(${overlay},0.09)`,
  s06dp: `rgba(${overlay},0.11)`,
  s08dp: `rgba(${overlay},0.12)`,
  s12dp: `rgba(${overlay},0.14)`,
  s16dp: `rgba(${overlay},0.15)`,
  s24dp: `rgba(${overlay},0.16)`,
  disabled: 'rgb(214, 214, 214)',
  disabledText: '#7A7A7A',
};

// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=8c05c2&secondary.color=cc1479
