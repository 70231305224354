/* eslint-disable react/jsx-props-no-spreading */
import { usePagination } from '@mui/material';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import AspectRatioImage from '../AspectRatioImage';
import Pagination from '../Pagination';

const ImageGallery = ({ images, ratio }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const handleChange = (event, value) => {
    setCurrentImage(value - 1);
  };

  const handlePrev = () => {
    if (currentImage === 0) {
      setCurrentImage(images.length - 1);
      return;
    }
    setCurrentImage(prev => prev - 1);
  };

  const handleNext = () => {
    if (currentImage === images.length - 1) {
      setCurrentImage(0);
      return;
    }
    setCurrentImage(prev => prev + 1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handlePrev,
    onSwipedRight: handleNext,
  });

  const { items } = usePagination({
    count: images.length,
    page: currentImage + 1,
  });

  const image = images[currentImage];

  return (
    <div {...handlers} className='relative'>
      <AspectRatioImage imageModal containerClassName='rounded' imageClassName='rounded' src={image} alt={image} ratio='3:2' />
      { images.length > 1 && (
        <div className='flex justify-center items-center py-2 space-x-2 absolute w-[100%] bottom-0 bg-s03dp'>
          <Pagination iconSize={22} handlePrev={handlePrev} handleNext={handleNext} handleChange={handleChange} items={items} />
        </div>
      ) }
    </div>
  );
};

export default ImageGallery;

// TODO:

/* <div
className='flex items-center justify-center'
style={{
  position: 'relative',
  paddingTop: 'calc(591.44 / 1127.34 * 100%)',
  width: '100%',
}}
>
<img
  style={{
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    objectFit: 'cover',
    position: 'absolute',
  }}
  src={image.url}
  alt={title || image.url}
  className='rounded w-full h-full px-2 py-2'
/>
</div>
*/
// ASPECT RATIO: 1127.34 / 591.44 = 2.8
// https://www.w3schools.com/howto/howto_css_aspect_ratio.asp
// https://stackoverflow.com/questions/60938707/how-to-fit-an-image-inside-a-fix-sized-div-without-distorting-the-image
