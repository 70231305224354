import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Grid } from '@mui/material';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { Breakpoints, CustomEvents, SimpleListUpdateActions } from '../../lib/constants';
import { breakpointNameSelector } from '../../models/settings/selector';
import ApiClientInstance from '../../clients/api';

const getImageCount = (images) => {
  let count = 0;
  if (images?.front) count += 1;
  if (images?.back) count += 1;
  if (images?.other?.length) count += images.other.length;
  return count;
};

export const AdminNoteSummaryColumnSizes = {
  Total: 24,
  Country: { xs: 8, lg: 6 },
  Price: { xs: 4, lg: 4 },
  Value: { xs: 4, lg: 4 },
  Serial: { xs: 6, lg: 4 },
  Images: { xs: 2, lg: 2 },
  Published: { xs: 3, lg: 3 },
  Quantity: { xs: 2, lg: 2 },
  Archive: { xs: 4, lg: 4 },
};

const AdminNoteSummaryItem = ({ note }) => {
  const breakpointName = useRecoilValue(breakpointNameSelector);

  const handleArchive = useCallback(async (e) => {
    e.stopPropagation();
    const res = await ApiClientInstance.sendRequest({
      method: 'put',
      path: `/admin/note/${note._id}/archive`,
      catchError: true,
    });
    if (res?.success) {
      emitCustomEvent(CustomEvents.SimpleItemListUpdate, {
        path: 'archived',
        updatePath: 'archived',
        action: SimpleListUpdateActions.Update,
        _id: res.data._id,
        updateValue: !!res.data.archived,
      });
    }
  }, []);

  const handleClick = useCallback((e) => {
    if (e.target.closest('.archive-btn') !== null) return null;
    if (!note) return;
    emitCustomEvent(CustomEvents.OpenEditNoteModal, { noteId: note._id });
  }, [note?._id]);

  if (!note) return null;

  const {
    country,
    denomination,
    grading,
    images,
    quantity,
    serial,
    tags,
    catalogue,
    price,
    archived,
    published,
  } = note;
  const {
    name: countryName,
  } = country;
  const singular = denomination?.name?.singular;
  const plural = denomination?.name?.plural;

  const catalogueString = `${catalogue?.prefix ? `${catalogue?.prefix} ` : ''}${catalogue?.value ? `${catalogue?.value}` : ''}${catalogue?.suffix ? `${catalogue?.suffix}` : ''}`;
  const noteTitle = `${country.name} ${catalogueString}`;

  return (
    <div
      role='button'
      tabIndex={0}
      onClick={handleClick}
      onKeyPress={handleClick}
      className='note-wrapper bg-surface py-[3px] rounded w-[100%] font-roboto text-primary-text pr-1 cursor-pointer'
    >
      <div className='note-inner py-3 bg-s01dp rounded px-2 tablet:px-4'>
        <Grid container columns={AdminNoteSummaryColumnSizes.Total} columnSpacing={1}>
          <Grid
            item
            xs={AdminNoteSummaryColumnSizes.Country.xs}
            lg={AdminNoteSummaryColumnSizes.Country.lg}
          >
            <div className='note-header capitalize text-sm'>
              { countryName }
            </div>
          </Grid>
          <Grid
            className='text-sm'
            item
            xs={AdminNoteSummaryColumnSizes.Serial.xs}
            lg={AdminNoteSummaryColumnSizes.Serial.lg}
          >
            { catalogueString }
          </Grid>
          <Grid
            className='text-sm'
            item
            xs={AdminNoteSummaryColumnSizes.Price.xs}
            lg={AdminNoteSummaryColumnSizes.Price.lg}
          >
            { price }
          </Grid>
          { breakpointName !== Breakpoints.Mobile && (
          <Grid
            className='text-sm'
            item
            xs={AdminNoteSummaryColumnSizes.Quantity.xs}
            lg={AdminNoteSummaryColumnSizes.Quantity.lg}
          >
            { quantity }
          </Grid>
          )}

          { breakpointName !== Breakpoints.Mobile && (
          <Grid
            item
            xs={AdminNoteSummaryColumnSizes.Images.xs}
            lg={AdminNoteSummaryColumnSizes.Images.lg}
            className='text-sm'
          >
            { getImageCount(images) }
          </Grid>
          )}

          { breakpointName !== Breakpoints.Mobile && (
          <Grid
            item
            xs={AdminNoteSummaryColumnSizes.Images.xs}
            lg={AdminNoteSummaryColumnSizes.Images.lg}
            className='text-sm'
          >
            { published ? new Date(published).toLocaleDateString() : 'N/A' }
          </Grid>
          )}

          <Grid
            item
            xs={AdminNoteSummaryColumnSizes.Archive.xs}
            lg={AdminNoteSummaryColumnSizes.Archive.lg}
            className='text-sm'
          >
            <Button
              variant='outlined'
              size='small'
              className='archive-btn'
              onClick={handleArchive}
            >
              { !archived ? 'Archive' : 'Unarchive' }
            </Button>
          </Grid>

        </Grid>
      </div>
    </div>
  );
};

export default AdminNoteSummaryItem;
