import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import {
  BrowserRouter,
} from 'react-router-dom';
import MainRouter from '../MainRouter';
import QueryProvider from '../../providers/query';
import PaletteProvider from '../../providers/palette';
// eslint-disable-next-line unused-imports/no-unused-vars
import '../../styles/css/index.css';
import ApiClientInstance from '../../clients/api';

const App = () => {
  useEffect(() => {
    ApiClientInstance.verifySession();
  }, []);

  return (
    <React.StrictMode>
      <RecoilRoot>
        <RecoilNexus />
        <PaletteProvider>
          <QueryProvider>
            <BrowserRouter>
              <MainRouter />
            </BrowserRouter>
          </QueryProvider>
        </PaletteProvider>
      </RecoilRoot>
    </React.StrictMode>
  );
};

export default App;
