import React from 'react';

const Wrapper = React.forwardRef(({
  children,
  className,
  applyBreakpoints,
}, ref) => (
  <section ref={ref} className={`wpc-wrapper ${className ? `${className}` : ''} ${applyBreakpoints ? ' desktop:w-[992px] desktop:ml-auto desktop:mr-auto text-primary-text' : ''}`}>
    { children }
  </section>
));

export default Wrapper;
