import { selector } from 'recoil';
import state from './atom';

export const gradingCompaniesSelector = selector({
  key: 'gradingCompaniesSelector',
  get: ({ get }) => {
    const gradingCompaniesState = get(state);
    return gradingCompaniesState.gradingCompanies;
  },
});
