import { atom } from 'recoil';
import ApiClientInstance from '../../clients/api';

const gradingCompaniesState = atom({
  key: 'gradingCompaniesState',
  default: {
    loading: true,
    error: false,
    gradingCompanies: [],
  },
  effects: [
    async ({ setSelf, onSet }) => {
      const init = async () => {
        const data = await ApiClientInstance.sendRequest({ method: 'get', path: '/grading-company', catchError: true });
        if (data.success) setSelf({ gradingCompanies: data.data, loading: false });
        else setSelf({ error: true, loading: false });
      };
      init();
    },
  ],
});

export default gradingCompaniesState;
