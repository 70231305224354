import Modal from '@mui/material/Modal';
import { nanoid } from 'nanoid';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilValue } from 'recoil';

import {
  IconButton,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import CoinLoader from '../Loaders/CoinLoader';
import CountrySelect from '../CountrySelect';
import AspectRatioContainer from '../AspectRatioContainer';
import GradingCompanySelect from '../GradingCompanySelect';
import DenominationSelect from '../DenominationSelect';
import ImageInput from '../ImageInput';
import ImageContainer from '../AdminEditNoteImageContainer';
import NoteEditLabel from '../AdminEditNoteLabel';

import ApiClientInstance from '../../clients/api';

import { emitCustomEvent, useCustomEvent } from '../../hooks/useCustomEventListener';
import { useUploadOnFileChange } from '../../hooks/useUploadOnFileChange';
import publishedDatesState from '../../models/publishDates/atom';
import { CustomEvents, BlankNote, SimpleListUpdateActions } from '../../lib/constants';
import { validateNoteUpdates, getNoteDifferences, formatNoteForUpdate } from '../../lib/validateNoteUpdates';
import { parseIntSafe } from '../../lib/parseIntSafe';

import './style.css';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '6px',
  p: 4,
  display: 'flex',
  maxWidth: '992px',
  justifyContent: 'center',
  alignItems: 'center',
};

const AdminEditNoteModal = ({
  styles,
}) => {
  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState(null);
  const [newNote, setNewNote] = useState(null);
  const [errors, setErrors] = useState({});
  const [diff, setDiff] = useState({});

  const publishedDates = useRecoilValue(publishedDatesState);
  const dateRef = useRef(new Date());

  const handleClose = useCallback(() => {
    setOpen(false);
    setNote(null);
    setNewNote(null);
    setErrors({});
    setDiff({});
    setIsNew(false);
  }, []);

  const handleEvent = useCallback(async ({ noteId }) => {
    if (!noteId) return;
    if (noteId === 'new') {
      setNote({ ...BlankNote });
      setIsNew(true);
      setNewNote({ ...BlankNote });
      setOpen(true);
      return;
    }
    setLoading(true);
    setOpen(true);
    const noteRes = await ApiClientInstance.sendRequest({
      method: 'GET',
      path: `/note/${noteId}`,
      catchError: true,
    });
    if (noteRes.success) {
      setNote(noteRes.data);
      setNewNote(noteRes.data);
    } else {
      setNote(null);
      setNewNote(null);
      setOpen(false);
    }
    setLoading(false);
  }, []);

  useCustomEvent(CustomEvents.OpenEditNoteModal, handleEvent);

  const handleUpload = useUploadOnFileChange();

  const errorCount = useMemo(() => Object.keys(errors).length, [JSON.stringify(errors)]);
  const modifiedCount = useMemo(() => Object.keys(diff).length, [JSON.stringify(diff)]);

  const title = useMemo(() => (
    <div className='edit-note-title flex justify-between items-center px-2 pt-2 bg-s01dp'>
      <div className='flex justify-start items-center'>
        <span className='font-bebas text-[20px] inline-block'>{`${isNew ? 'Create' : 'Edit'} Note`}</span>
        { errorCount > 0 && <span className='ml-2 text-sm text-error'>{`${errorCount} error${errorCount > 1 ? 's' : ''}`}</span> }
        { modifiedCount > 0 && <span className='ml-2 text-sm text-primary-dark'>{`${modifiedCount} change${modifiedCount > 1 ? 's' : ''}`}</span> }
      </div>
      <IconButton
        size='small'
        edge='end'
        onClick={handleClose}
      >
        <CloseIcon sx={{ color: 'var(--color-text-primary)' }} />
      </IconButton>
    </div>
  ), [errorCount, modifiedCount, isNew]);

  const imagesModified = useMemo(() => {
    if (!note || !newNote) return false;
    return (diff['images.front'] || diff['images.back'] || diff['images.other']);
  }, [JSON.stringify(diff)]);

  useEffect(() => {
    if (!newNote) return;
    const { errors: validationErrors, updates } = validateNoteUpdates(formatNoteForUpdate(newNote));
    const _diff = getNoteDifferences(note, newNote);
    setErrors(validationErrors);
    setDiff(_diff);
  }, [newNote, note]);

  const body = useMemo(() => {
    if (!note || !newNote) return null;

    const handleChangeWrapper = (setter) => (e) => setter(e.target.value);

    const setCountry = (country) => setNewNote(prev => ({ ...prev, country }));

    const setDenominationValue = (value) => setNewNote(prev => ({ ...prev, denomination: { ...prev.denomination, value } }));
    const setRedenominatedValue = (redenominatedValue) => setNewNote(prev => ({ ...prev, denomination: { ...prev.denomination, redenominatedValue } }));
    const setDenominationName = (name) => setNewNote(prev => ({ ...prev, denomination: { ...prev.denomination, name } }));
    const setDenominationDisplayValue = (displayValue) => setNewNote(prev => ({ ...prev, denomination: { ...prev.denomination, displayValue } }));

    const setGradingGrade = (grade) => setNewNote(prev => ({ ...prev, grading: { ...prev.grading, grade: parseIntSafe(grade, '') } }));
    const setGradingCompany = (company) => setNewNote(prev => ({ ...prev, grading: { ...prev.grading, company } }));
    const setGradingNotes = (notes) => setNewNote(prev => ({ ...prev, grading: { ...prev.grading, notes } }));

    const setPrice = (price) => setNewNote(prev => ({ ...prev, price: parseIntSafe(price, '') }));

    const setMaxYear = (max) => setNewNote(prev => ({ ...prev, year: { ...prev.year, max: parseIntSafe(max, '') } }));
    const setMinYear = (min) => setNewNote(prev => ({ ...prev, year: { ...prev.year, min: parseIntSafe(min, '') } }));

    const setQuantity = (quantity) => setNewNote(prev => ({ ...prev, quantity: parseIntSafe(quantity, '') }));
    const setSerial = (serial) => setNewNote(prev => ({ ...prev, serial }));

    const setCatalogueValue = (value) => setNewNote(prev => ({ ...prev, catalogue: { ...prev.catalogue, value } }));
    const setCataloguePrefix = (prefix) => setNewNote(prev => ({ ...prev, catalogue: { ...prev.catalogue, prefix } }));
    const setCatalogueSuffix = (suffix) => setNewNote(prev => ({ ...prev, catalogue: { ...prev.catalogue, suffix } }));

    const setCost = (cost) => setNewNote(prev => ({ ...prev, cost: parseIntSafe(cost, '') }));

    const setPublished = (e) => setNewNote(prev => ({ ...prev, published: e.target.value }));

    const setImagesFront = (front, frontOriginal) => setNewNote(prev => ({ ...prev, images: { ...prev.images, front, original: { ...prev.images.original, front: frontOriginal } } }));
    const setImagesBack = (back, backOriginal) => setNewNote(prev => ({ ...prev, images: { ...prev.images, back, original: { ...prev.images.original, back: backOriginal } } }));
    const addAdditionalImage = (img, imgOriginal) => setNewNote(prev => {
      const prevOther = prev?.images?.other || [];
      return ({ ...prev, images: { ...prev.images, other: [...prevOther, img], original: { ...prev.images.original, other: [...prev.images.original.other, imgOriginal] } } });
    });

    const handleUploadAdditionalImage = handleUpload({
      title: `${newNote._id || nanoid(8)}-${nanoid(8)}`,
      foldername: 'note',
      callback: ({ url, compressed }) => addAdditionalImage(compressed.url, url),
      compress: true,
    });

    const replaceAdditionalImage = (index) => (img, imgOriginal) => setNewNote(prev => {
      const prevOther = prev?.images?.other || [];
      const newOther = [...prevOther];
      newOther[index] = img;
      const prevOtherOriginal = prev?.images?.original?.other || [];
      const newOtherOriginal = [...prevOtherOriginal];
      newOtherOriginal[index] = imgOriginal;
      return ({ ...prev, images: { ...prev.images, other: newOther, original: { ...prev.images.original, other: newOtherOriginal } } });
    });

    const removeAdditionalImage = (img, originalImg) => setNewNote(prev => ({ ...prev, images: { ...prev.images, other: prev.images.other.filter(i => i !== img), original: { ...prev.images.original, other: prev.images.original.other.filter(i => i !== originalImg) } } }));

    const notePublished = !!note.published;
    const dateIncluded = notePublished && publishedDates.some((d) => d.toISOString() === new Date(note.published).toISOString());

    return (
      <>
        <div className='note-edit-group'>
          <NoteEditLabel modified={diff.country} label='published date' className='note-edit-group-header mb-2' hasError={errors.published} errorText={errors.published} />
          <FormControl sx={{ width: '50%' }}>
            <InputLabel size='small' id='published'>select a date</InputLabel>
            <Select
              className='bg-s02dp'
              labelId='published'
              id='published'
              value={newNote.published || ''}
              label='select a date'
              onChange={setPublished}
              size='small'
            >
              {
                (note.published && !dateIncluded)
                  ? (
                    <MenuItem dense key={new Date(note.published).toISOString()} value={new Date(note.published).toISOString()}>
                      {new Date(note.published).toDateString()}
                    </MenuItem>
                  )
                  : <MenuItem dense key={dateRef.current.toISOString()} value={dateRef.current.toISOString()}>{dateRef.current.toDateString()}</MenuItem>
              }
              { publishedDates.map((date) => <MenuItem dense key={date.toISOString()} value={date.toISOString()}>{ date.toDateString() }</MenuItem>) }
            </Select>
          </FormControl>
        </div>

        <div className='note-edit-group'>
          <NoteEditLabel modified={diff.country} label='country' className='note-edit-group-header' hasError={errors.country} errorText={errors.country} />
          <CountrySelect size='small' country={newNote.country} setCountry={setCountry} />
        </div>

        <Grid container columns={12} columnSpacing={3}>
          <Grid item xs={12} md={6}>
            <div className='note-edit-group'>
              <div className='note-edit-group-header'>inventory</div>
              <NoteEditLabel modified={diff.price} label='price' errorText={errors.price} hasError={errors.price} />
              <TextField
                type='number'
                inputProps={{
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                }}
                onChange={handleChangeWrapper(setPrice)}
                size='small'
                value={newNote.price || ''}
              />
              <NoteEditLabel modified={diff.quantity} label='quantity' errorText={errors.quantity} hasError={errors.quantity} />
              <TextField
                error={errors?.quantity}
                type='number'
                inputProps={{
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                }}
                onChange={handleChangeWrapper(setQuantity)}
                size='small'
                value={newNote.quantity || ''}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className='note-edit-group'>
              <div className='note-edit-group-header'>denomination</div>
              <NoteEditLabel modified={diff['denomination.name']} label='name' errorText={errors['denomination.name']} hasError={errors['denomination.name']} />
              <DenominationSelect size='small' setDenomination={setDenominationName} denomination={newNote.denomination?.name} />
              <NoteEditLabel modified={diff['denomination.value']} label='value' errorText={errors['denomination.value']} hasError={errors['denomination.value']} />
              <TextField
                type='number'
                step='any'
                inputProps={{
                  maxLength: 36,
                  min: 0,
                }}
                onChange={handleChangeWrapper(setDenominationValue)}
                size='small'
                value={newNote.denomination?.value}
              />
              <NoteEditLabel modified={diff['denomination.redenominatedValue']} label='redenomination' errorText={errors['denomination.redenominatedValue']} hasError={errors['denomination.redenominatedValue']} />
              <TextField
                type='number'
                step='any'
                inputProps={{
                  maxLength: 36,
                  min: 0,
                }}
                onChange={handleChangeWrapper(setRedenominatedValue)}
                size='small'
                value={newNote.denomination?.redenominatedValue || ''}
              />
              <NoteEditLabel modified={diff['denomination.displayValue']} label='display value' errorText={errors['denomination.displayValue']} hasError={errors['denomination.displayValue']} />
              <TextField onChange={handleChangeWrapper(setDenominationDisplayValue)} size='small' value={newNote.denomination?.displayValue || ''} />
            </div>
          </Grid>
        </Grid>

        <Grid container columns={12} columnSpacing={3}>
          <Grid item xs={12} md={6}>
            <div className='note-edit-group'>
              <div className='note-edit-group-header'>grading</div>
              <NoteEditLabel modified={diff['grading.company']} label='company' errorText={errors['grading.company']} hasError={errors['grading.company']} />
              <GradingCompanySelect size='small' setGradingCompany={setGradingCompany} gradingCompany={newNote.grading?.company} />
              <NoteEditLabel modified={diff['grading.grade']} label='grade' errorText={errors['grading.grade']} hasError={errors['grading.grade']} />
              <TextField
                type='number'
                inputProps={{
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                }}
                onChange={handleChangeWrapper(setGradingGrade)}
                size='small'
                value={newNote.grading?.grade || ''}
              />
              <NoteEditLabel modified={diff['grading.notes']} label='notes' />
              <TextField multiline onChange={handleChangeWrapper(setGradingNotes)} fullWidth size='small' value={newNote.grading?.notes || ''} />

            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className='note-edit-group'>
              <div className='note-edit-group-header'>catalogue</div>
              <NoteEditLabel modified={diff['catalogue.prefix']} label='prefix' errorText={errors['catalogue.prefix']} hasError={errors['catalogue.prefix']} />
              <TextField onChange={handleChangeWrapper(setCataloguePrefix)} size='small' value={newNote.catalogue?.prefix || ''} />
              <NoteEditLabel modified={diff['catalogue.value']} label='value' errorText={errors['catalogue.value']} hasError={errors['catalogue.value']} />
              <TextField
                type='number'
                inputProps={{
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                }}
                onChange={handleChangeWrapper(setCatalogueValue)}
                size='small'
                value={newNote.catalogue?.value || ''}
              />
              <NoteEditLabel modified={diff['catalogue.suffix']} label='suffix' errorText={errors['catalogue.suffix']} hasError={errors['catalogue.suffix']} />
              <TextField onChange={handleChangeWrapper(setCatalogueSuffix)} size='small' value={newNote.catalogue?.suffix || ''} />
            </div>
          </Grid>
        </Grid>

        <Grid container columns={12} columnSpacing={3}>
          <Grid item xs={12} md={6}>
            <div className='note-edit-group'>
              <div className='note-edit-group-header'>year</div>
              <NoteEditLabel modified={diff['year.min']} label='min' errorText={errors['year.min']} hasError={errors['year.min']} />
              <TextField
                type='number'
                inputProps={{
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                }}
                onChange={handleChangeWrapper(setMinYear)}
                size='small'
                value={newNote.year?.min || ''}
              />
              <NoteEditLabel modified={diff['year.max']} label='max' errorText={errors['year.max']} hasError={errors['year.max']} />
              <TextField
                type='number'
                inputProps={{
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                }}
                onChange={handleChangeWrapper(setMaxYear)}
                size='small'
                value={newNote.year?.max || ''}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className='note-edit-group'>
              <div className='note-edit-group-header'>other</div>
              <NoteEditLabel modified={diff.serial} label='serial' errorText={errors.serial} hasError={errors.serial} />
              <TextField onChange={handleChangeWrapper(setSerial)} size='small' value={newNote.serial || ''} />
              <NoteEditLabel modified={diff.cost} label='cost' errorText={errors.cost} hasError={errors?.cost} />
              <TextField onChange={handleChangeWrapper(setCost)} size='small' value={newNote?.cost || ''} />
            </div>
          </Grid>

        </Grid>

        <div className='note-edit-group'>
          <NoteEditLabel modified={imagesModified} label='images' className='note-edit-group-header' />
          <Grid container columns={12} columnSpacing={3}>
            <Grid item xs={12} md={6}>
              <ImageContainer modified={diff['images.front']} label='front' setter={setImagesFront} image={newNote.images?.front} alt={newNote.country?.name} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ImageContainer label='back' modified={diff['images.back']} setter={setImagesBack} image={newNote.images?.back} alt={newNote.country?.name} />
            </Grid>
            { newNote.images?.other.length > 0 && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                { newNote.images?.other.map((image, index) => (
                  <Grid item xs={12} md={6} key={image}>
                    <ImageContainer
                      modified={diff['images.other']?.updateValue?.[index] !== diff['images.other']?.value?.[index]}
                      isAdditional
                      originalImage={newNote.images?.original?.other[index]}
                      label={`additional image ${index + 1}`}
                      add={addAdditionalImage}
                      replace={replaceAdditionalImage(index)}
                      remove={removeAdditionalImage}
                      image={image}
                      alt={newNote.country?.name}
                    />
                  </Grid>
                ))}
              </>
            )}

            <Grid item xs={12} md={6}>
              <div className='note-edit-value-label'><span className='invisible'>new image</span></div>
              <div className='note-edit-image-container'>
                <AspectRatioContainer ratio='3:2'>
                  <div className='h-[100%] bg-s01dp rounded flex justify-center items-center'>
                    <ImageInput handleChange={handleUploadAdditionalImage} id='additional-image'>
                      <Button
                        variant='outlined'
                        component='span'
                      >
                        add additional image
                      </Button>
                    </ImageInput>
                  </div>
                </AspectRatioContainer>
              </div>
            </Grid>
          </Grid>
        </div>

      </>
    );
  }, [note, newNote, errors, imagesModified, diff]);

  const handleSave = useCallback(async () => {
    const _newNote = formatNoteForUpdate(newNote);
    const { updates, errors: validationErrors } = validateNoteUpdates(_newNote);
    const hasErrors = Object.keys(validationErrors).length > 0;
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    const _diff = getNoteDifferences(formatNoteForUpdate(note), updates);
    const data = Object.keys(_diff).reduce((acc, key) => {
      acc[key] = _diff[key].updateValue;
      return acc;
    }, {});
    const res = await ApiClientInstance.sendRequest({
      method: isNew ? 'post' : 'put',
      path: isNew ? '/admin/note' : `/admin/note/${note._id}`,
      catchError: true,
      data,
    });
    if (res?.success) {
      setNote(res.data);
      setNewNote(res.data);
      setErrors({});
      setIsNew(false);
    } else {
      // TODO: handle error
      console.log(res);
    }
  }, [newNote, note, isNew]);

  const hasChanges = useMemo(() => {
    if (!note || !newNote) return false;
    return modifiedCount > 0;
  }, [modifiedCount]);

  const handleArchive = useCallback(async () => {
    const res = await ApiClientInstance.sendRequest({
      method: 'put',
      path: `/admin/note/${note._id}/archive`,
      catchError: true,
    });
    if (res?.success) {
      setNote(res.data);
      setNewNote(res.data);
      setErrors({});
      setIsNew(false);
      emitCustomEvent(CustomEvents.SimpleItemListUpdate, {
        path: 'archived',
        updatePath: 'archived',
        action: SimpleListUpdateActions.Update,
        _id: res.data._id,
        updateValue: !!res.data.archived,
      });
    } else {
      console.log(res);
    }
  }, [note?._id]);

  const archive = useMemo(() => {
    if (isNew || !note?._id) return null;
    return (
      <div>
        <NoteEditLabel modified={false} label={!note?.archived ? 'Archive Note' : 'Unarchive Note'} className='note-edit-group-header' hasError={false} />
        <p className='py-2'>
          { !note?.archived && 'Archiving this note will remove it from the public view, but you can still edit it and unarchive it later.' }
          { note?.archived && 'Unarchiving this note will restore it and it will then be visible to the public.' }
        </p>
        <Button
          onClick={handleArchive}
          sx={{ minWidth: '300px' }}
          color='error'
          variant='outlined'
        >
          { note?.archived ? 'Unarchive Note' : 'Archive Note' }
        </Button>
      </div>
    );
  }, [isNew, note?.archived, note?._id, handleArchive]);

  const save = useMemo(() => (
    <div className='edit-note-title flex justify-center items-center px-2 py-3 bg-s01dp space-x-8'>
      <Button
        onClick={hasChanges ? () => setNewNote(note) : () => handleClose()}
        sx={{ minWidth: '100px' }}
        variant='outlined'
      >
        { hasChanges ? 'Reset' : 'Close' }
      </Button>
      <Button
        disabled={!hasChanges || errorCount > 0}
        sx={{ minWidth: '100px' }}
        variant='contained'
        onClick={handleSave}
      >
        { isNew ? 'Create' : 'Save' }
      </Button>
    </div>
  ), [hasChanges, note, newNote, handleClose, handleSave, errorCount, isNew]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='image-container'
      aria-describedby='modal-modal-description'
    >
      <div style={modalStyle} className='w-[100%] relative'>
        <div className='bg-surface rounded w-[100%] text-primary-text'>
          { title }
          <div className='flex flex-col rounded break-words h-[65vh] overflow-auto px-2 pb-2 bg-s01dp'>
            { loading && <CoinLoader boxType='fullContainer' />}
            { body }
            { archive }
          </div>
          { save }
        </div>
      </div>
    </Modal>
  );
};

export default AdminEditNoteModal;
