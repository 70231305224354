import CircleIcon from '@mui/icons-material/Circle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from '@mui/material/IconButton';
import { useMemo } from 'react';

const Pagination = ({
  items,
  handleChange,
  handlePrev,
  handleNext,
  iconSize = 18,
}) => {
  const arrowIconSize = `${iconSize}px`;

  const [circleIconStyles, arrowIconStyles] = useMemo(() => [{
    height: `${iconSize - 6}px`,
    width: `${iconSize - 6}px`,
  },
  {
    height: `${iconSize}px`,
    width: `${iconSize}px`,
  }], [iconSize]);

  return (
    <>
      <IconButton sx={arrowIconStyles} size='small' onClick={handlePrev}>
        <ArrowBackIosNewIcon sx={{ opacity: '.7', ...arrowIconStyles }} />
      </IconButton>
      { items.map((item, i) => {
        const {
          type, selected, disabled, page,
        } = item;
        if (type !== 'page') return null;
        return (
          <IconButton sx={circleIconStyles} onClick={(e) => handleChange(e, page)} type='button' disabled={disabled} aria-current={item['aria-current']} size='small' key={item.page}>
            <CircleIcon sx={{ opacity: '.7', ...circleIconStyles }} color={selected ? 'primaryLight' : 'textPrimary'} />
          </IconButton>
        );
      })}
      <IconButton sx={arrowIconStyles} className='flex items-center' size='small' onClick={handleNext}>
        <ArrowForwardIosIcon sx={{ opacity: '.7', ...arrowIconStyles }} />
      </IconButton>
    </>
  );
};

export default Pagination;
