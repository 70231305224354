import { Wrapper, Content } from '../../components/Containers';
import AdminEditNoteModal from '../../components/AdminEditNoteModal';

const About = () => (
  <>
    <Wrapper>
      <Content>
        <Wrapper applyBreakpoints>
          <Content>
            <h1 className='py-2 text-[36px] font-bebas text-center'>About World Banknote Sales</h1>
            <div className='tablet:flex px-3 tablet:px-2 desktop:px-0'>
              <div className='pr-6 rounded h-[300px] flex-shrink-0 justify-center flex'>
                <img className='rounded max-h-[300px]' src='https://cdn.worldbanknotesales.com/upload/RAatZWfK.png' alt='Austin Mattox - World Banknote Sales' />
              </div>
              <div className='text-md space-y-3 mt-2 tablet:mt-0'>
                <p>
                  Welcome to World Banknote Sales, a world leader in banknotes from around the world. Our team of experienced dealers is dedicated to bringing you the best selection of banknotes, including rare and hard-to-find items that are sure to enhance any collection.  World Banknote Sales is part of&nbsp;
                  <a className='text-primary-dark font-bold' href='https://www.mattoxcoinsandcurrency.com/' target='__blank' rel='noopener'>Mattox Coins and Currency</a>
                  , which has been serving local and international clients for over 50 years!
                </p>
                <p>
                  At World Banknote Sales, we believe that everyone should have access to the beauty and history of global currencies. We have placed many notes in the most advanced collections worldwide and take great care to offer banknotes not available anywhere else.  We also work with many central banks, universities, and museums to assist them with their buying and selling needs.
                </p>
                <p>
                  We pride ourselves on our commitment to customer service and satisfaction. Our team is available to answer any questions you may have, and we strive to provide a seamless shopping experience from start to finish.
                </p>
              </div>
            </div>

          </Content>
        </Wrapper>
      </Content>
    </Wrapper>
    <AdminEditNoteModal />
  </>
);

export default About;
