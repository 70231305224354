export const SiteCookies = {
  AuthKey: 'authkey',
};

export const VirtuosoTypes = {
  List: 'list',
  Grid: 'grid',
};

export const CustomEvents = {
  // used primarily for simple list general rerender
  Rerender: 'Rerender',
  // used for specific simple item list functionality
  SimpleItemListUpdate: 'simpleItemListUpdate',
  OpenImageModal: 'openImageModal',
  OpenSiteMenu: 'openSiteMenu',
  Redirect: 'redirect',
  OpenEditNoteModal: 'openEditNoteModal',
  OpenNoteFilterModal: 'openNoteFilterModal',
  CloseNoteFilterModal: 'closeNoteFilterModal',
};

export const SimpleListUpdateActions = {
  Add: 'add',
  Remove: 'remove',
  Update: 'update',
  Log: 'log',
};

export const Breakpoints = {
  Mobile: 'mobile',
  Tablet: 'tablet',
  Desktop: 'desktop',
  WideDesktop: 'wideDesktop',
};

export const DialogTypes = {
  Confirmation: 'confirmation',
  Component: 'component',
};

export const UserRoles = {
  User: 'user',
  Moderator: 'moderator',
  Admin: 'admin',
  SuperAdmin: 'superadmin',
};

export const BreakpointValues = {
  Mobile: {
    Min: 0,
    Max: 767,
  },
  Tablet: {
    Min: 768,
    Max: 991,
  },
  Desktop: {
    Min: 992,
    Max: 1199,
  },
  WideDesktop: {
    Min: 1200,
    Max: 99999,
  },
};

export const SignUpFormClasses = {
  Content: 'mx-4 my-4 rounded bg-s02dp desktop:w-[992px] tablet:mx-auto desktop:flex desktop:align-center desktop:items-center',
  MainDiv: 'py-5 px-3 form-container desktop:w-[992px] tablet:mx-auto',
  Form: 'registration-form tablet:w-[600px] font-roboto mb-8 mt-4 tablet:mx-auto',
};

export const BlankNote = {
  country: null,
  denomination: {
    value: '',
    name: null,
    redenominatedValue: null,
    displayValue: '',
  },
  grading: {
    grade: '',
    notes: '',
    company: null,
  },
  quantity: 0,
  serial: '',
  published: '',
  tags: [],
  images: {
    front: '',
    back: '',
    original: {
      front: '',
      back: '',
      other: [],
    },
    other: [],
  },
  catalogue: {
    prefix: '',
    value: '',
    suffix: '',
    _id: '',
  },
  price: '',
  year: {
    min: '',
  },
};

export const PublishedDateStart = '6/27/2023 09:00:00 AM';
