import { selector } from 'recoil';
import state from './atom';

export const countriesSelector = selector({
  key: 'countriesSelector',
  get: ({ get }) => {
    const countriesState = get(state);
    return countriesState.countries;
  },
});
