import { useEffect } from 'react';

const getElement = () => document.querySelector('.wpc-event-div');

export const useCustomEvent = (eventName, eventHandler, dependencies = []) => {
  useEffect(() => {
    if (!eventName) return null;
    const element = getElement();
    const handleEvent = (event) => {
      eventHandler(event.detail);
    };
    element.addEventListener(eventName, handleEvent, false);
    return () => {
      element.removeEventListener(eventName, handleEvent);
    };
  }, [eventHandler, ...dependencies]);
};

export const emitCustomEvent = (eventName, data) => {
  const element = getElement();
  const event = new CustomEvent(eventName, { detail: data });
  element.dispatchEvent(event);
};

window.emitCustomEvent = emitCustomEvent;
