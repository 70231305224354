import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Grid, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Breakpoints, CustomEvents, VirtuosoTypes } from '../../lib/constants';
import AdminNoteSummaryItem, { AdminNoteSummaryColumnSizes } from '../AdminNoteSummaryItem';
import { Wrapper, Content } from '../Containers';
import NoteList from '../NoteList';

import NoteListFilter from '../NoteListFilter';
import NoteListHeader from '../NoteListHeader';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { breakpointNameSelector } from '../../models/settings/selector';

const atomKey = 'admin-inventory';

const _AdminNoteSummaryItem = ({ item }) => <AdminNoteSummaryItem note={item} />;
const FilterComponent = () => <NoteListFilter atomKey={atomKey} />;
const TotalItemsComponent = ({ totalItems }) => (
  <div className='py-2 pl-2 tablet:pl-4 font-semibold italic text-sm'>
    {`${totalItems} ${totalItems === 1 ? 'note' : 'notes'} found`}
  </div>
);

const NewNoteButton = () => {
  const handleClick = useCallback(() => {
    emitCustomEvent(CustomEvents.OpenEditNoteModal, { noteId: 'new' });
  }, []);
  return (
    <Button
      variant='contained'
      size='small'
      startIcon={<AddCircleOutlineIcon />}
      onClick={handleClick}
    >
      Add
    </Button>
  );
};

const Header = () => {
  const breakpointName = useRecoilValue(breakpointNameSelector);

  return (
    <div className='note-wrapper bg-surface py-[3px] rounded w-[100%] font-roboto text-primary-text pr-3'>
      <div className='note-inner rounded px-2 tablet:px-4'>
        <Grid container columns={AdminNoteSummaryColumnSizes.Total} columnSpacing={1}>
          <Grid
            item
            xs={AdminNoteSummaryColumnSizes.Country.xs}
            lg={AdminNoteSummaryColumnSizes.Country.lg}
          >
            <div className='note-header text-sm'>
              country
            </div>
          </Grid>
          <Grid
            className='text-sm'
            item
            xs={AdminNoteSummaryColumnSizes.Serial.xs}
            lg={AdminNoteSummaryColumnSizes.Serial.lg}
          >
            catalogue
          </Grid>
          <Grid
            className='text-sm'
            item
            xs={AdminNoteSummaryColumnSizes.Price.xs}
            lg={AdminNoteSummaryColumnSizes.Price.lg}
          >
            price
          </Grid>
          { breakpointName !== Breakpoints.Mobile && (
          <Grid
            className='text-sm'
            item
            xs={AdminNoteSummaryColumnSizes.Quantity.xs}
            lg={AdminNoteSummaryColumnSizes.Quantity.lg}
          >
            qty.
          </Grid>
          )}
          { breakpointName !== Breakpoints.Mobile && (
          <Grid
            item
            xs={AdminNoteSummaryColumnSizes.Images.xs}
            lg={AdminNoteSummaryColumnSizes.Images.lg}
            className='text-sm'
          >
            img.
          </Grid>
          ) }
          { breakpointName !== Breakpoints.Mobile && (
          <Grid
            item
            xs={AdminNoteSummaryColumnSizes.Images.xs}
            lg={AdminNoteSummaryColumnSizes.Images.lg}
            className='text-sm'
          >
            published
          </Grid>
          ) }
          <Grid
            item
            xs={AdminNoteSummaryColumnSizes.Images.xs}
            lg={AdminNoteSummaryColumnSizes.Images.lg}
            className='text-sm'
          >
            actions
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const AdminInventory = () => (
  <Wrapper>
    <NoteListHeader HeaderButton={NewNoteButton} filterOptions={{ showQuantity: true, showAdmin: true }} FilterComponent={FilterComponent} title='inventory' atomKey={atomKey} />
    <Header />
    <Content className='wpc-note-list'>
      <NoteList showTotalItems TotalItemsComponent={TotalItemsComponent} customScrollParentSelector='.wpc-note-list' atomKey={atomKey} listType={VirtuosoTypes.List} ItemComponent={_AdminNoteSummaryItem} />
    </Content>
  </Wrapper>
);

export default AdminInventory;
