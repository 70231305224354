import { useCallback } from 'react';
import ApiClientInstance from '../clients/api';
import { resizeFile } from '../lib/resizeFile';

const { uploadImage } = ApiClientInstance;

export const useUploadOnFileChange = () => {
  const uploadOnFileChange = useCallback(({
    foldername,
    title,
    callback = null,
    shouldResize = false,
    compress = false,
  }) => async (e) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    if (file.type.includes('image')) {
      let _image = file;
      if (shouldResize) _image = await resizeFile(file);
      const imageUploadData = await uploadImage({
        image: _image,
        foldername,
        title,
        compress,
      });
      if (callback) callback(imageUploadData?.data);
      return imageUploadData;
    }
  }, []);

  return uploadOnFileChange;
};
