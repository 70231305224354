/* eslint-disable react/jsx-props-no-spreading */
import { IconButton } from '@mui/material';
import Modal from '@mui/material/Modal';
import {
  useCallback,
  useMemo,
  useState,
} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { useCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';

import NoteListFilter from '../NoteListFilter';
import NoteListFilterActions from '../NoteListFilterActions';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '6px',
  p: 4,
  display: 'flex',
  maxWidth: '992px',
  justifyContent: 'center',
  alignItems: 'center',
};

const NoteListFilterModal = () => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({});
  const [atomKey, setAtomKey] = useState('');

  const handleEvent = useCallback(({ atomKey: _atomKey, options: _options }) => {
    if (!_atomKey) return;
    setAtomKey(_atomKey);
    if (_options) setOptions(_options);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setAtomKey('');
    setOptions({});
  }, []);

  useCustomEvent(CustomEvents.OpenNoteFilterModal, handleEvent);
  useCustomEvent(CustomEvents.CloseNoteFilterModal, handleClose);

  const title = useMemo(() => (
    <div className='edit-note-title flex justify-between items-center px-2 pt-2 bg-s01dp'>
      <div className='flex justify-start items-center'>
        <span className='font-bebas text-[20px] inline-block'>Sort and Filter Notes</span>
      </div>
      <IconButton
        size='small'
        edge='end'
        onClick={handleClose}
      >
        <CloseIcon sx={{ color: 'var(--color-text-primary)' }} />
      </IconButton>
    </div>
  ), []);

  if (!atomKey || !open) return null;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='image-container'
      aria-describedby='modal-modal-description'
    >
      <div style={modalStyle} className='w-[100%] relative'>
        <div className='bg-surface rounded w-[100%] text-primary-text'>
          { title }
          <div className='flex flex-col rounded break-words h-[65vh] overflow-auto px-2 pb-2 bg-s01dp'>
            <NoteListFilter {...options} atomKey={atomKey} />
          </div>
          <div className='pb-2 bg-s01dp px-2'>
            <NoteListFilterActions atomKey={atomKey} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NoteListFilterModal;
